import React from 'react';
import { Modal, Container, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const IframeModal = ({ open, onClose, iframeUrl, accessCode }) => (
    <Modal
        open={open}
        onClose={onClose}
        style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent'  // Added white background to Modal
        }}
    >
        <Container
            style={{
                height: '100vh',
                width: '100%',
                padding: 0,
                backgroundColor: 'white',  // Explicit white background on Container
                position: 'relative'
            }}
        >
            <IconButton
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 100,
                    zIndex: 1000,
                    color: 'red',
                }}
                onClick={onClose}
            >
                <CloseIcon />
                
            </IconButton>
            <iframe
                src={iframeUrl}
                title="FillOnline Iframe"
                style={{
                    width: '100%',
                    height: '100vh',
                    border: 'none',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: 'white'  // Added white background to iframe
                }}
                allowFullScreen
                // headers={{
                //     Authorization: `Bearer ${accessCode}`
                // }}
            />
        </Container>
    </Modal>
);

export default IframeModal;