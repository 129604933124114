
import React, { useEffect, useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useRecoilState } from 'recoil';
import { formState } from '../../../../recoil/profile-atom';
import { API } from "../../../../services/api-config";
import { postApiCallDynamic } from "../../../../services/api-service";
import { useSetRecoilState } from 'recoil';
import { draftTaskTriggerState, draftTaskPopUpTriggerState } from '../../../../recoil/profile-atom';

export const DraftTaskUploadConditionForm = ({ handleClose, formId, file, onUploadSuccess }) => {
    const { handleSubmit } = useForm();
    const [uploadedTasks, setUploadedTasks] = useState([]);
    const setDraftTaskTrigger = useSetRecoilState(draftTaskTriggerState);
    const setDraftTaskPopUpTrigger = useSetRecoilState(draftTaskPopUpTriggerState)
    const formOnSubmit = (data) => {
        // Handle form submission
        console.log(data);
    };
    const formData = {
        formId: formId,
        documentType: "conditionalDocument",
        file: file
    };

    console.log("formId confirmation ", formData.formId)


    const uploadDocCustom = async (formData) => {
        console.log("Uploading...");
        localStorage.setItem('uploadconditions', "true");
        window.dispatchEvent(new Event('storage')); // Trigger storage event
        try {
            // Create FormData object for file upload
            const formDataToSend = new FormData();
            formDataToSend.append('formId', formData.formId); // Add form ID
            formDataToSend.append('file', formData.file); // Add the file object
            formDataToSend.append('documentType', "conditionalApproval"); // Add document type

            console.log("formdatatosend   :", formDataToSend);
            handleClose();

            // Make the API call to upload the document
            const response = await postApiCallDynamic({
                data: formDataToSend, // Send FormData
                refresh: (res) => res, // Return raw response
                path: API.postDraftTaskUpload, // API endpoint for uploading the document
                header: 'POST', // HTTP method
            });
            localStorage.setItem('uploadconditions', "false");
            // Handle Conflict Error: If status is 409, log the error details.
            if (response.status === 409) {
                const errorData = await response.json(); // Parse the error response
                console.error('Conflict Error:', errorData); // Log conflict error details
                handleClose();

            }

            // Validate Response: Check if the response structure is valid and handle error codes.
            if (!response || !response.status || !response.data) {
                handleClose();
                throw new Error('Invalid response structure');

            }

            if (response.data.errorCode) {
                const { errorCode, errorMessage } = response.data; // Extract error code and message
                console.log('Error Code:', errorCode); // Log error code
                console.log('Error Message:', errorMessage); // Log error message
                handleClose();

                if (errorCode === 9095) {
                    setErrorStatus(true); // Set error status if errorCode 9095
                    handleClose();
                    console.error('Error:', errorMessage || 'Invalid form ID or data');
                } else if (errorCode === 9098) {
                    console.error('Error:', errorMessage || 'Uploaded document details not matched with borrower details');
                    handleClose();
                    return { success: false, message: response?.data?.errorMessage || 'Invalid document' };
                }
            } else {
                // Assuming response.data contains the tasks array
                setUploadedTasks(response.data);
                onUploadSuccess(response.data);
                setDraftTaskTrigger(prev => prev + 1);
                setDraftTaskPopUpTrigger(prev => prev + 1)

                handleClose();


                return { success: true, data: response.data };
            }


        } catch (error) {
            console.error('Error sending form ID and file:', error.message); // Handle any other errors
            handleClose();
            return { success: false, message: 'Unexpected error occurred' };
        }
    };
    // useEffect(() => {
    //     if (formData.file) {
    //         handleFileSubmit();
    //     }
    // }, [formData.file]);
    // Function to submit the file and formId to the API
    const handleFileSubmit = async () => {

        try {
            localStorage.setItem("checkLoader", "true");
            const result = await uploadDocCustom(formData);
            if (result.success && result.data) {
                onUploadSuccess(result.data); // Pass the tasks data to parent
                handleClose(); // Close the form after successful upload
            }

            localStorage.setItem("checkLoader", "false");

        } catch (error) {
            console.error('Error uploading document:', error);
            handleClose();
        }

    };
    console.log(formData)
    console.log(file)




    return (

        <form onSubmit={handleSubmit(formOnSubmit)}>
            <Typography variant="body1" paragraph>
                Are you sure you want to generate tasks?
            </Typography>

            <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} mt={5} mb={5}>
                <Button
                    variant="contained"
                    type="submit"
                    onClick={handleFileSubmit}
                >
                    Yes
                </Button>
                <Button variant="text" onClick={handleClose}>
                    No
                </Button>
            </Stack>
        </form>
    );
};
