/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */

const API_CONTEXT_PATH = "/ks/api/v1";
const API_CONTEXT_PATH_KS_LOAN = "/ks-loan/api/v1";
const API_CONTEXT_PATH_CHAT = "/ks-chat/api/v1";
const API_CONTEXT_PATH_BILL = "/ks-bill/api/v1";

const API_SIGNUP = `${API_CONTEXT_PATH}/signup`;

const API_FORGET = `${API_CONTEXT_PATH}/forget/password`;

const API_SIGNUP_OTP = `${API_CONTEXT_PATH}/signup/otp`;

const API_SIGNIN_OTP = `${API_CONTEXT_PATH}/signin/otp`;

const API_FORGET_OTP = `${API_CONTEXT_PATH}/forget/otp`;

const API_VERIFY_OTP = `${API_CONTEXT_PATH}/otps`;
const API_SECURED_OTP = `${API_CONTEXT_PATH}/secured/otps`;

const API_SET_PASSWOrd = `${API_CONTEXT_PATH}/set/password`;

const API_PRE_SIGNIN = `${API_CONTEXT_PATH}/signin`;


const API_CREATE_MASTER_TASK = `${API_CONTEXT_PATH_KS_LOAN}/task-master`;
const API_CREATE_MASTER_TASK_DOC = `${API_CONTEXT_PATH_KS_LOAN}/master-task-doc`;
const API_GET_MASTER_TASK_DOC = `${API_CONTEXT_PATH_KS_LOAN}/master-task-doc-list?taskTypeName=ALL`;
const API_GET_MASTER_TASK_DOC_BY_TASK_ID = `${API_CONTEXT_PATH_KS_LOAN}/master-task-doc-list?taskTypeName=`;
const API_GET_MASTER_TASK = `${API_CONTEXT_PATH_KS_LOAN}/task-master-list?taskMasterFor=all`;
const API_DOWNLOAD_DOC = `${API_CONTEXT_PATH_KS_LOAN}/document-lib-doc`;
const API_THIRD_PARTY_TASKS_LISTS = `${API_CONTEXT_PATH_KS_LOAN}/third-party-list`;
const API_GET_THIRD_PARTY_OTP = `${API_CONTEXT_PATH_KS_LOAN}/act`;
const API_VALIDATE_THIRD_PARTY = `${API_CONTEXT_PATH_KS_LOAN}/third-party-validate-otp`
const API_GET_THIRD_PARTY_DETIALS = `${API_CONTEXT_PATH_KS_LOAN}/third-party-secured`
const API_FINAL_THIRD_PARTY_RESOLVE = `${API_CONTEXT_PATH_KS_LOAN}/third-party-secured`
const API_UPLOAD_DOC = `${API_CONTEXT_PATH_KS_LOAN}/document-lib-doc`;


const API_GET_REALTOR_OTP = `${API_CONTEXT_PATH_KS_LOAN}/realtors-view`;
const API_VALIDATE_REALTOR = `${API_CONTEXT_PATH_KS_LOAN}/realtors-view/validate-otp`
const API_GET_REALTOR_DETIALS = `${API_CONTEXT_PATH_KS_LOAN}/realtors-view/loan-summary`

const API_CREATE_LOAN_DOCUMENT = `${API_CONTEXT_PATH_KS_LOAN}/loan-document`
const API_GET_LOAN_DOCUMENT = `${API_CONTEXT_PATH_KS_LOAN}/loan-documents`

const API_THIRD_PARTY_PROXY_DOCUMENT_OTHERS_UPLOAD = `${API_CONTEXT_PATH_KS_LOAN}/secured/proxy-doc/others-upload`;
const API_DOWNLOAD_DOC_RESOLVE = `${API_CONTEXT_PATH_KS_LOAN}/secured/document-lib-doc`;

const API_CREATE_THIRD_PARTY_TASK = `${API_CONTEXT_PATH_KS_LOAN}/third-party`;
const API_GET_THIRD_PARTY_TASK = `${API_CONTEXT_PATH_KS_LOAN}/third-party`;

const API_THIRD_PARTY_CHAT_MESSAGE = `${API_CONTEXT_PATH_KS_LOAN}/task-comment`
const API_THIRD_PARTY_CHAT_MESSAGE_SECURE = `${API_CONTEXT_PATH_KS_LOAN}/secured/task-comment`

const API_PASS_CHANGE_REQ = `${API_CONTEXT_PATH}/otp`;
const API_LOAN_INVITATION = `${API_CONTEXT_PATH}/loan/invitation`;
const API_LOAN_INVITATION_EMAIL = `${API_CONTEXT_PATH}/invitation/remail`;

const API_ROLE = `${API_CONTEXT_PATH}/roles`;

const API_GENERATEOTP = `${API_CONTEXT_PATH}/users/invite/otp`;

const API_RESEND_OTP = `${API_CONTEXT_PATH}/users/otp/resend`;

const API_VERIFY_INVITATION = `${API_CONTEXT_PATH}/users/invite/verify`;

const API_OWN_INFO = `${API_CONTEXT_PATH}/settings/personal-info`;

const API_STATES = `${API_CONTEXT_PATH}/settings/usa-states`;

const API_NMLS_Create = `${API_CONTEXT_PATH}/settings/license-mapping`;

const API_NMLS_ADMIN_UPDATE = `${API_CONTEXT_PATH}/admin/license-status`;

const API_INFO = `${API_CONTEXT_PATH}/settings/info`;

const API_WEB_INFO = `${API_CONTEXT_PATH}/settings/web-info`;
const API_WEB_PUBLISH = `${API_CONTEXT_PATH}/settings/web-publish`;

const API_REVIEWS = `${API_CONTEXT_PATH}/settings/web-info/reviews`;
const API_RATES = `${API_CONTEXT_PATH}/settings/web-info/rates`;
const API_BIO = `${API_CONTEXT_PATH}/settings/web-info/bio`;


const API_SOCIAL_MEDIA_LINK_UPDATE = `${API_CONTEXT_PATH}/settings/web-info/links`;

const API_DEMO_REQUEST = `${API_CONTEXT_PATH}/admin/demo-request`;
const API_ADMIN = `${API_CONTEXT_PATH}/settings/admin`;

const API_DEMO_STATUS_CHANGE = `${API_CONTEXT_PATH}/admin/demo-request/status`;
const API_NOTE = `${API_CONTEXT_PATH}/admin/demo-request/note`;

const API_PREFERENCE_CREDIT = `${API_CONTEXT_PATH_KS_LOAN}/settings/preference`;

const API_TEAM_INVITATION = `${API_CONTEXT_PATH}/invitation`;
const API_TEAMS = `${API_CONTEXT_PATH}/teams`;
const API_REASSIGN = `${API_CONTEXT_PATH}/teams/re-assign`;

const API_USER_LIST = `${API_CONTEXT_PATH}/admin/user-list`;
const API_USER_INFO = `${API_CONTEXT_PATH}/admin/user-info`;
const API_NMLS_STATUS_Change = `${API_CONTEXT_PATH}/admin/nmlsid-status`;


const API_USER_PAYMENT_AUTO_PAY_STATUS = `${API_CONTEXT_PATH_BILL}/user/getBillingUser`;
const API_USER_PAYMENT_AUTO_PAY_CANCEL = `${API_CONTEXT_PATH_BILL}/payment/payment-setup/cancel`;




const API_LOAN_COUNT = `${API_CONTEXT_PATH_KS_LOAN}/loan/count`;
const API_LOANS = `${API_CONTEXT_PATH_KS_LOAN}/loans`;
const API_LOAN_APPLICATIONS = `${API_CONTEXT_PATH_KS_LOAN}/loan-applications`;

const API_ADDITIONAL_CONTACT = `${API_CONTEXT_PATH_KS_LOAN}/additional-contact`;
const API_LOAN_NOTE = `${API_CONTEXT_PATH_KS_LOAN}/form-note`;

const API_GET_STARTED = `${API_CONTEXT_PATH_KS_LOAN}/form/get-started`;
const API_INVITE_CO_BORROWERS = `${API_CONTEXT_PATH_KS_LOAN}/form/invite-co-borrowers`;
const API_LOAN_DETAILS = `${API_CONTEXT_PATH_KS_LOAN}/form/loan-details`;
const API_BORROWER_INFO_PERSONAL = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/personal`;

const API_PARTNER_INFO_PERSONAL = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/personal`;

const API_BORROWER_INFO_CONTACT = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/contact`;
const API_PARTNER_INFO_CONTACT = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/contact`;

const API_BORROWER_INFO_ADDITIONAL_INCOME = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/additional-income`;
const API_PARTNER_INFO_ADDITIONAL_INCOME = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/additional-income`;

const API_BORROWER_INFO_MILITARY_SERVICE = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/military-service`;
const API_PARTNER_INFO_MILITARY_SERVICE = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/military-service`;

const API_BORROWER_INFO_EMPLOYMENT = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/employment`;
const API_PARTNER_INFO_EMPLOYMENT = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/employment`;

const API_BORROWER_INFO_DECLARATION = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/declarations`;
const API_PARTNER_INFO_DECLARATION = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/declarations`;
const API_BORROWER_INFO_DEPENDENT = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/dependent`;
const API_PARTNER_INFO_DEPENDENT = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/dependent`;
const API_BORROWER_INFO_DEMOGRAPHIC = `${API_CONTEXT_PATH_KS_LOAN}/form/borrower-info/demographic`;
const API_PARTNER_INFO_DEMOGRAPHIC = `${API_CONTEXT_PATH_KS_LOAN}/form/partner-info/demographic`;
const API_ASSET_CREDIT = `${API_CONTEXT_PATH_KS_LOAN}/form/asset-credit`;
const API_REAL_STATE = `${API_CONTEXT_PATH_KS_LOAN}/form/real-estate`;
const API_GIFT = `${API_CONTEXT_PATH_KS_LOAN}/form/gift-grants`;
const API_CREDIT_REPORT = `${API_CONTEXT_PATH_KS_LOAN}/form/credit-report`;
const API_CREDCO_PULL = `${API_CONTEXT_PATH_KS_LOAN}/credco-pull`;
const API_CREDIT_REPRT_DOWNLOAD = `${API_CONTEXT_PATH_KS_LOAN}/download-credit-report`;
const API_LIABILITIES = `${API_CONTEXT_PATH_KS_LOAN}/form/liabilities`;
const API_REVIEW = `${API_CONTEXT_PATH_KS_LOAN}/form/review`;
const API_SUBMIT = `${API_CONTEXT_PATH_KS_LOAN}/form/submit`;

const API_LOAN_EDIT_PERMISSION = `${API_CONTEXT_PATH_KS_LOAN}/form/edit-permission`;

const BORROWER_INVITATION_LIST = `${API_CONTEXT_PATH}/invitation`;
const INVITATION_STATUS = `${API_CONTEXT_PATH}/invitation/status`;

const DEMO_SCHEDULE = `${API_CONTEXT_PATH}/admin/demo-schedule`;
const ADMIN_CONTACT_US = `${API_CONTEXT_PATH}/admin/contact-us`;
const CREDCO_CREDENTIALS = `${API_CONTEXT_PATH_KS_LOAN}/credco-credentials`;

// loan application

const FORM_DATA = `${API_CONTEXT_PATH_KS_LOAN}/form`;

// Mismo
const API_MISMO_EXPORT = `${API_CONTEXT_PATH_KS_LOAN}/mismo`;

const API_FULL_LIST_DOC_DOWNLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-doc/complete/download`;

const API_GUIDED_DOCUMENTS = `${API_CONTEXT_PATH_KS_LOAN}/form/documents/guided-document`;
const API_GUIDED_DOCUMENTS_OVERRIDE = `${API_CONTEXT_PATH_KS_LOAN}/form/documents/guided-document/override`;
const API_ADDITIONAL_DOCUMENTS = `${API_CONTEXT_PATH_KS_LOAN}/form/documents/additional-document`;

const API_PROXY_USER_UPLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-user/upload`;
const API_PROXY_DOCUMENT_UPLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-doc/upload`;
const API_PROXY_DOCUMENT_OTHERS_UPLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-doc/others-upload`;
const API_PROXY_USER_DOWNLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-user/download`;
const API_PROXY_DOCUMENT_DOWNLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-doc/download`;
const API_PROXY_DOCUMENT_DOWNLOAD_THIRD_PARTY = `${API_CONTEXT_PATH_KS_LOAN}/secured/proxy-doc/download`;
const API_PROXY_USER_REMOVE = `${API_CONTEXT_PATH_KS_LOAN}/proxy-user/remove`;

const API_MLO_LOAN_DOCUMENT = `${API_CONTEXT_PATH_KS_LOAN}/form/loan-documents`;

const API_LEADS = `${API_CONTEXT_PATH_KS_LOAN}/leads`;
const API_LEAD = `${API_CONTEXT_PATH_KS_LOAN}/lead`;
const API_LEAD_DOWNLOAD = `${API_CONTEXT_PATH_KS_LOAN}/proxy-user/download`;
const API_LEAD_SEND = `${API_CONTEXT_PATH_KS_LOAN}/send`;

// Loan Summary Start
// Main API
const API_LOAN_SUMMARY_MAIN = `${API_CONTEXT_PATH_KS_LOAN}/loan-info`;

// Change MLO MLP
const API_CHANGE_MLO_MLP = `${API_CONTEXT_PATH_KS_LOAN}/loan-assign`;

// Loan State
const API_LOAN_STATE_CHANGE = `${API_CONTEXT_PATH_KS_LOAN}/loan-state`;

// Tasks
const API_TASK_TAB_COUNT = `${API_CONTEXT_PATH_KS_LOAN}/task/count`;
const API_TASKS_LISTS = `${API_CONTEXT_PATH_KS_LOAN}/tasks`;
const API_TASK = `${API_CONTEXT_PATH_KS_LOAN}/task`;
const API_TASK_STATUS = `${API_CONTEXT_PATH_KS_LOAN}/task/status`;

// Loan Summary Statistics
const API_LOAN_SUMMARY_STATISTICS = `${API_CONTEXT_PATH_KS_LOAN}/loan-summary`;
// Loan Summary End


const API_MSG_NOTIFIER = `${API_CONTEXT_PATH_KS_LOAN}/msg/notifier`;

// api path

// Chat
const CHAT_TOKEN = `${API_CONTEXT_PATH_CHAT}/chat/user/token`;
const NOTIF_TOKEN = `${API_CONTEXT_PATH_CHAT}/notification/userToken`;
const API_BILL_USER = `${API_CONTEXT_PATH_BILL}/user`;
const API_ACTIVE_USER = `${API_BILL_USER}/getActiveUser`
const API_BILL_INVOICE = `${API_CONTEXT_PATH_BILL}/invoice`;
const API_DOWNLOAD_INVOICE = `${API_CONTEXT_PATH_BILL}`;
const API_COUPON = `${API_CONTEXT_PATH_BILL}`
const API_DELETE_COUPON = `${API_COUPON}/deleteCoupon`
const API_GET_COUPON = `${API_COUPON}/getCoupon`
const API_ADD_COUPON = `${API_COUPON}/Coupon`
const API_VALID_BROKER_STATES = `${API_CONTEXT_PATH}/settings/eligible-states`
const API_DOC_AVATAR = `${API_CONTEXT_PATH_KS_LOAN}/download-avatar`;

// API for the credit report 
const API_POST_CREDCO_ACCOUNT_2 = `${API_CONTEXT_PATH_KS_LOAN}/credit-account-preference`
const API_POST_CREDIT_REPORT_2 = `${API_CONTEXT_PATH_KS_LOAN}/credit-report`
const API_CREDIT_REPORT_2 = `${API_CONTEXT_PATH_KS_LOAN}/credit-account-preference`
// coded by santha for draft task management 
//const API_DRAFT_TASK_2 = `${API_CONTEXT_PATH_KS_LOAN}/task/drafted-task`
//const API_POST_DRAFT_TASK_UPLOAD_2 = `${API_CONTEXT_PATH_KS_LOAN}/task/drafted-task`
//const API_PUT_FOR_DELETE_DRAFT_TASK_2 = `${API_CONTEXT_PATH_KS_LOAN}/remove-task`


// coded by santha for draft task management 
const API_DRAFT_TASK_2 = `${API_CONTEXT_PATH_KS_LOAN}/task/drafted-task`
const API_POST_DRAFT_TASK_UPLOAD_2 = `${API_CONTEXT_PATH_KS_LOAN}/task/drafted-task`
const API_PUT_FOR_DELETE_DRAFT_TASK_2 = `${API_CONTEXT_PATH_KS_LOAN}/remove-task`
const API_GET_FILL_ONLINE_DOCUMENT = `${API_CONTEXT_PATH_KS_LOAN}/fill-online/blank-doc-type`
export class API {

  static get createThirdPartyTask() {
    return API_CREATE_THIRD_PARTY_TASK;
  }

  static get getThirdPartyTask() {
    return API_GET_THIRD_PARTY_TASK;
  }

  static get createMasterTask() {
    return API_CREATE_MASTER_TASK;
  }

  static get createMasterTaskDoc() {
    return API_CREATE_MASTER_TASK_DOC;
  }

  static get getMasterTaskDoc() {
    return API_GET_MASTER_TASK_DOC
  }

  static get getThirdPartyOtp() {
    return API_GET_THIRD_PARTY_OTP
  }

  static get getRealtorOtp() {
    return API_GET_REALTOR_OTP
  }

  static get getThirdPartyTaskDetails() {
    return API_GET_THIRD_PARTY_DETIALS
  }

  static get getRealtorDetails() {
    return API_GET_REALTOR_DETIALS
  }

  static get proxyDocumentThirdPartyOthersUpload() {
    return API_THIRD_PARTY_PROXY_DOCUMENT_OTHERS_UPLOAD
  }

  static get validateThirdPartyOtp() {

    return API_VALIDATE_THIRD_PARTY
  }

  static get validateRealtorOtp() {
    return API_VALIDATE_REALTOR
  }

  static get finalThirdPartyResolve() {
    return API_FINAL_THIRD_PARTY_RESOLVE
  }

  static getMasterTaskDocByTaskID() {

    return API_GET_MASTER_TASK_DOC_BY_TASK_ID;
  }


  static get getMasterTask() {
    return API_GET_MASTER_TASK;
  }

  static get createLoanDocument() {
    return API_CREATE_LOAN_DOCUMENT;

  }
  static get getLoanDocuments() {
    return API_GET_LOAN_DOCUMENT
  }

  static get downloadDoc() {
    return API_DOWNLOAD_DOC;
  }

  static get uploadDoc() {
    return API_UPLOAD_DOC;
  }

  static get downloadDocResolve() {
    return API_DOWNLOAD_DOC_RESOLVE;
  }

  static get lead() {
    return API_LEAD;
  }

  static get leads() {
    return API_LEADS;
  }
  static get leadSend() {

    return API_LEAD_SEND;
  }
  static get leadDownload() {
    return API_LEAD_DOWNLOAD;
  }

  static get mismoExport() {
    return API_MISMO_EXPORT;
  }

  static get fullListDocumentExport() {
    return API_FULL_LIST_DOC_DOWNLOAD;
  }

  static get formData() {
    return FORM_DATA;
  }

  static get credcoCredentials() {
    return CREDCO_CREDENTIALS;
  }
  static get demoSchedule() {
    return DEMO_SCHEDULE;
  }

  static get adminContactUs() {
    return ADMIN_CONTACT_US;
  }

  static get borrowerInvitationList() {
    return BORROWER_INVITATION_LIST;
  }


  static get invitationStatus() {
    return INVITATION_STATUS;
  }

  static get admin() {
    return API_ADMIN;
  }
  static get loans() {
    return API_LOANS;
  }
  static get loanApplications() {
    return API_LOAN_APPLICATIONS;
  }
  static get loanCount() {
    return API_LOAN_COUNT;
  }

  static get nmlsStatusChange() {
    return API_NMLS_STATUS_Change;
  }
  static get userInfo() {
    return API_USER_INFO;
  }

  static get userPaymentAutoPayStatus() {
    return API_USER_PAYMENT_AUTO_PAY_STATUS
  }

  static get userPaymentAutoPayCancel() {
    return API_USER_PAYMENT_AUTO_PAY_CANCEL
  }


  static get userList() {
    return API_USER_LIST;
  }
  static get note() {
    return API_NOTE;
  }
  static get demoRequest() {
    return API_DEMO_REQUEST;
  }

  static get demoStatusChange() {
    return API_DEMO_STATUS_CHANGE;
  }

  // User Profile Settings Start
  static get preferenceCredit() {
    return API_PREFERENCE_CREDIT;
  }

  static get teams() {
    return API_TEAMS;
  }

  static get reAssign() {
    return API_REASSIGN;
  }

  static get teamInvitaion() {
    return API_TEAM_INVITATION;
  }

  static get bio() {
    return API_BIO;
  }
  static get socialMediaLinkUpdate() {
    return API_SOCIAL_MEDIA_LINK_UPDATE;
  }

  static get rates() {
    return API_RATES;
  }

  static get reviews() {
    return API_REVIEWS;
  }

  static get webInfo() {
    return API_WEB_INFO;
  }

  static get webPublish() {
    return API_WEB_PUBLISH;
  }

  static get info() {
    return API_INFO;
  }

  static get ownInfo() {
    return API_OWN_INFO;
  }

  static get getStates() {
    return API_STATES;
  }

  static get createNMLA() {
    return API_NMLS_Create;
  }

  static get adminUpdateNMLA() {
    return API_NMLS_ADMIN_UPDATE;
  }

  // User Profile Settings End

  static get verifyInvitation() {
    return API_VERIFY_INVITATION;
  }
  static get securedOTP() {
    return API_SECURED_OTP;
  }

  static get verifyOTP() {
    return API_VERIFY_OTP;
  }

  static get resendOtp() {
    return API_RESEND_OTP;
  }

  static get generateOtp() {
    return API_GENERATEOTP;
  }
  static get role() {
    return API_ROLE;
  }
  static get loanInvitaion() {
    return API_LOAN_INVITATION;
  }
  static get loanInvitaionEmail() {
    return API_LOAN_INVITATION_EMAIL;
  }
  static get passChangeReq() {
    return API_PASS_CHANGE_REQ;
  }
  static get forget() {
    return API_FORGET;
  }

  static get signinOtp() {
    return API_VERIFY_OTP;
  }

  static get signup() {
    return API_SIGNUP;
  }

  static get signupOtp() {
    return API_VERIFY_OTP;
  }

  static get forgetOtp() {
    return API_VERIFY_OTP;
  }

  static get setPassword() {
    return API_SET_PASSWOrd;
  }

  static get preSignin() {
    return API_PRE_SIGNIN;
  }

  // Loan list start
  static get additionalContact() {
    return API_ADDITIONAL_CONTACT;
  }

  static get loanNote() {
    return API_LOAN_NOTE;
  }

  static get getStarted() {
    return API_GET_STARTED;
  }

  static get inviteCoBorrowers() {
    return API_INVITE_CO_BORROWERS;
  }

  static get loanDetails() {
    return API_LOAN_DETAILS;
  }

  static get borrowerInfoPersonal() {
    return API_BORROWER_INFO_PERSONAL;
  }

  static get partnerInfoPersonal() {
    return API_PARTNER_INFO_PERSONAL;
  }

  static get borrowerInfoContact() {
    return API_BORROWER_INFO_CONTACT;
  }
  static get partnerInfoContact() {
    return API_PARTNER_INFO_CONTACT;
  }

  static get borrowerInfoEmployment() {
    return API_BORROWER_INFO_EMPLOYMENT;
  }
  static get partnerInfoEmployment() {
    return API_PARTNER_INFO_EMPLOYMENT;
  }

  static get borrowerInfoAdditionalIncome() {
    return API_BORROWER_INFO_ADDITIONAL_INCOME;
  }
  static get partnerInfoAdditionalIncome() {
    return API_PARTNER_INFO_ADDITIONAL_INCOME;
  }

  static get borrowerInfoMilitaryService() {
    return API_BORROWER_INFO_MILITARY_SERVICE;
  }
  static get partnerInfoMilitaryService() {
    return API_PARTNER_INFO_MILITARY_SERVICE;
  }

  static get borrowerInfoDeclarations() {
    return API_BORROWER_INFO_DECLARATION;
  }

  static get partnerInfoDeclarations() {
    return API_PARTNER_INFO_DECLARATION;
  }

  static get chatMessage() {
    return API_THIRD_PARTY_CHAT_MESSAGE
  }
  static get chatMessageSecure() {
    return API_THIRD_PARTY_CHAT_MESSAGE_SECURE
  }

  static get borrowerInfoDependent() {
    return API_BORROWER_INFO_DEPENDENT;
  }

  static get partnerInfoDependent() {
    return API_PARTNER_INFO_DEPENDENT;
  }

  static get borrowerInfoDemographic() {
    return API_BORROWER_INFO_DEMOGRAPHIC;
  }
  static get partnerInfoDemographic() {
    return API_PARTNER_INFO_DEMOGRAPHIC;
  }

  static get assetCredit() {
    return API_ASSET_CREDIT;
  }

  static get realEstate() {
    return API_REAL_STATE;
  }

  static get gift() {
    return API_GIFT;
  }

  static get creditReport() {
    return API_CREDIT_REPORT;
  }

  static get credcoPull() {
    return API_CREDCO_PULL;
  }

  static get liabilities() {
    return API_LIABILITIES;
  }

  static get review() {
    return API_REVIEW;
  }

  static get submit() {
    return API_SUBMIT;
  }

  static get loanEditPermission() {
    return API_LOAN_EDIT_PERMISSION;
  }

  static get guidedDocument() {
    return API_GUIDED_DOCUMENTS;
  }

  static get guidedDocumentOverride() {
    return API_GUIDED_DOCUMENTS_OVERRIDE;
  }

  static get additionalDocument() {
    return API_ADDITIONAL_DOCUMENTS;
  }

  static get proxyUserUpload() {
    return API_PROXY_USER_UPLOAD;
  }

  static get proxyDocumentUpload() {
    return API_PROXY_DOCUMENT_UPLOAD;
  }

  static get proxyDocumentOthersUpload() {
    return API_PROXY_DOCUMENT_OTHERS_UPLOAD;
  }

  static get proxyUserDownload() {
    return API_PROXY_USER_DOWNLOAD;
  }

  static get proxyDocumentDownload() {
    return API_PROXY_DOCUMENT_DOWNLOAD;
  }

  static get proxyDocumentDownloadThirdParty() {
    return API_PROXY_DOCUMENT_DOWNLOAD_THIRD_PARTY
  }

  static get proxyUserRemove() {
    return API_PROXY_USER_REMOVE;
  }

  static get mloLoanDocument() {
    return API_MLO_LOAN_DOCUMENT;
  }
  // Loan Summary API Start
  // Main API
  static get loanSummaryMainAPI() {
    return API_LOAN_SUMMARY_MAIN;
  }

  // Change MLO MLP
  static get loanChangeMloMlp() {
    return API_CHANGE_MLO_MLP;
  }

  // Loan State Change
  static get loanSummaryLoanStateAPI() {
    return API_LOAN_STATE_CHANGE;
  }

  // Taks Tab API
  static get taskTabCount() {
    return API_TASK_TAB_COUNT;
  }

  static get taskLists() {
    return API_TASKS_LISTS;
  }

  static get thirdPartyTaskLists() {
    return API_THIRD_PARTY_TASKS_LISTS;
  }


  static get task() {
    return API_TASK;
  }

  static get taskStatus() {
    return API_TASK_STATUS;
  }

  // Loan Summary Statistics API
  static get loanSummaryStatistics() {
    return API_LOAN_SUMMARY_STATISTICS;
  }

  // Loan Summary API End
  static get chatUserToken() {
    return CHAT_TOKEN;
  }

  static get notificationUserToken() {
    return NOTIF_TOKEN;
  }

  static get msgNotifier() {
    return API_MSG_NOTIFIER;
  }


  static get creditReportDownload() {
    return API_CREDIT_REPRT_DOWNLOAD;
  }

  static get billUser() {
    return API_BILL_USER;
  }

  static get activeUsers() {
    return API_ACTIVE_USER;
  }
  static get billInvoice() {
    return API_BILL_INVOICE;
  }
  static get downloadInvoice() {
    return API_DOWNLOAD_INVOICE;
  }

  static get billCoupon() {
    return API_COUPON;
  }

  static get deleteCoupon() {
    return API_DELETE_COUPON;
  }

  static get couponDetails() {
    return API_GET_COUPON;
  }

  static get addCoupon() {
    return API_ADD_COUPON;
  }

  static get validStates() {
    return API_VALID_BROKER_STATES;
  }
  static get getAvatar() {
    return API_DOC_AVATAR;
  }

  static get postDraftTaskUpload() {
    return API_POST_DRAFT_TASK_UPLOAD_2
  }
  static get removeDraftTask() {
    return API_PUT_FOR_DELETE_DRAFT_TASK_2
  }

  static get getFillOnlineDocument() {
    return API_GET_FILL_ONLINE_DOCUMENT
  }


  // function calls the API for the get and post the credit report details 
  static get postCredcoAccount() {
    return API_POST_CREDCO_ACCOUNT_2
  }

  static get getCredcoAccount() {
    return API_POST_CREDCO_ACCOUNT_2
  }


  static get postCreditDocument() {
    return API_POST_CREDIT_REPORT_2
  }

  static get creditReport2() {
    return API_CREDIT_REPORT_2
  }

  // static get postDraftTaskUpload() {
  //   return API_POST_DRAFT_TASK_UPLOAD_2
  // }

  // static get removeDraftTask() {
  //   return API_PUT_FOR_DELETE_DRAFT_TASK_2
  // }
  // commenting for cahnge
}