


import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { Box, Button, FormHelperText, IconButton, Stack, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks, DocumentDialogCommonHooks, PreviewPDFModal } from '../../../helpers/modal/modal'
import CustomReactMaterialTable from '../../../helpers/table/react-material-table'
import { API } from '../../../services/api-config'
import ConfirmationModel from '../../../helpers/modal/confirm-modal'
import { KSDocumentSearchIcon, KSPencilAltIcon, KSTrashIcon } from '../../../icons/custome-icon'
import DocumentLibraryForm from './DocumentLibraryForm'
import { getApiCallDynamic, postApiCallDynamicWithOutReturn } from '../../../services/api-service'
import { CREATE, PUT, UPDATE } from '../../../constants/constatnt-variable'
import { kstheme } from '../../../theme/theme'
import FilePreview from '../../broker/leads/file-preview'
import { docPreview, documentPreview } from '../../../helpers/file-upload-download'
import { previewDocument } from '../../../helpers/preview-document'
import { set } from 'lodash'


export default function DocumenLibrary() {



    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));

    const route = useNavigate()
    const location = useLocation();

    const [taskList, setTaskList] = useState([]);
    const [fillonlineTaskList, setFillonlineTaskList] = useState([]);

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTask,
                // param: { formId: formId, status: status },
            }).then((res) => {
                if (res?.status === 200) {
                    setTaskList(res?.data);
                    console.log("taskList  ;", taskList)
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []);

    console.log("taskList  ;", taskList)

    const [refreshStatus, setRefreshStatus] = useState(false)
    const [docEdit, setDocEdit] = useState();
    const [docList, setDocList] = useState([]);
    const [docListShow, setDocListShow] = useState([]);
    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTaskDoc,
                // param: { formId: formId, status: status },
            }).then((res) => {
                if (res?.status === 200) {
                    console.log("resres", res?.data)
                    setDocList(res?.data);
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, [refreshStatus]);

    console.log("taskList  ;", docList)

    const [fillOnlineDocList, setFillOnlineDocList] = useState([]);

    // Update the API call
    useEffect(() => {
        const getFillableDocuments = async () => {
            try {
                let param = { docType: "All" };
                const res = await getApiCallDynamic({
                    path: API.getFillOnlineDocument,
                    param: param
                });
                if (res?.status === 200) {
                    // Process the data before setting it
                    const processedList = res.data.map(doc => ({
                        id: doc.id,
                        label: doc.blankDocType,
                        value: doc.fileStorageKey,
                        fileName: doc.fileName,
                        jsonFileName: doc.jsonFileName,
                        jsonStorageKey: doc.jsonStorageKey
                    }));
                    setFillOnlineDocList(processedList);
                }
            } catch (error) {
                console.error('Error fetching fillable documents:', error);
            }
        };

        getFillableDocuments();
    }, [refreshStatus]);

    useEffect(() => {
        const updatedDocListShow = docList
            .map(doc => ({
                id: doc.id,
                documentType: doc.documentType,
                documentDescriptor: doc.documentDescriptor,
                storageKey: doc.storageKey,
                documentNote: doc.documentNote,
                fileName: doc.fileName,
                blankDocument: doc.blankDocument,
                isFillableDocument: doc.isFillableDocument,
                taskTypeName: doc.taskMaster?.taskTypeName
            }))
        // .sort((a, b) => a.documentType.localeCompare(b.documentType));

        setDocListShow(updatedDocListShow);
    }, [docList]);



    const taskTypeOptions = taskList.map((task) => ({
        label: task.taskTypeName,
        value: task.id,
    }));

    const fillOnlineOptions = docList.map((task) => ({
        label: task.fileName,
        value: task.id,
    }));




    let [formValue, setFormValue] = useState([])



    let [customError, setCustomerror] = useState("")

    const [editState, setEditState] = useState(() => {
        return null
    })


    const [uploadData, setUploadData] = useState(null);

    // Edit table
    useEffect(() => {
        if (editState != null) {
            let list = _.cloneDeep(docList)
            let editItem = list.find((item, i) => i == editState)
            console.log(editItem, 'editItemeditItem')
            setDocEdit(editItem)
            setModalOpen(true);
        }

    }, [editState])

    useEffect(() => {
        // let list = _.cloneDeep(docList)
        // let editItem = list.find((item, i) => i == editState)
        // console.log(editItem, 'editItemeditItem')
        // setDocEdit(editItem)
        // setModalOpen(true);
    }, [])


    const [deleteState, setDeleteState] = useState(() => {
        return null
    })


    // delete table
    useEffect(() => {
        if (deleteState != null) {
            docList.splice(deleteState, 1)
            setDocList([...docList])
            setDeleteState(null)
        }

    }, [deleteState])

    const updateState = () => {

        setRefreshStatus(!refreshStatus)
        setUploadData(null)

    }

    const tableColumn = useMemo(
        () => [

            {
                accessorKey: "taskTypeName",
                header: "Task Type",
                enableColumnActions: false,

            },
            // {
            //     accessorKey: "documentType",
            //     header: "Document Type",
            //     enableColumnActions: false,
            //     Cell: ({ row }) => (
            //         <>
            //             <Typography>
            //                 {row.original['documentType']}
            //             </Typography>
            //             {row.original['blankDocument'] && (
            //                 <Typography variant="body2" sx={{ color: '#D94166' }}>
            //                     Blank form
            //                 </Typography>
            //             )}
            //             <Typography variant="body2" sx={{ color: 'grey' }}>
            //                 {row.original['fileName']}
            //             </Typography>
            //         </>


            //     ),
            // },
            // {
            //     accessorKey: "documentDescriptor",
            //     header: "Document Descriptor",
            //     enableColumnActions: false,
            // },
            {
                accessorKey: 'documentType',
                header: 'Document Type',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            whiteSpace: 'normal', // Allows text to wrap
                            wordBreak: 'break-word', // Break long words
                            maxWidth: '300px', // Set your desired max width
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentType']}
                        </Typography>
                        {row.original['blankDocument'] && (
                            <Typography variant="body2" sx={{ color: '#D94166' }}>
                                Blank form
                            </Typography>
                        )}
                        <Typography variant="body2" sx={{ color: 'grey' }}>
                            {row.original['fileName']}
                        </Typography>
                    </Box>
                ),
            },
            {
                accessorKey: 'documentDescriptor',
                header: 'Document Descriptor',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            maxWidth: '300px',
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentDescriptor']}
                        </Typography>
                    </Box>
                ),
            },

            {
                accessorKey: 'documentNote',
                header: 'Note',
                enableColumnActions: false,
                Cell: ({ row }) => (
                    <Box
                        sx={{
                            whiteSpace: 'normal',
                            wordBreak: 'break-word',
                            maxWidth: '300px',
                        }}
                    >
                        <Typography variant="body1" sx={{ overflowWrap: 'break-word' }}>
                            {row.original['documentNote']}
                        </Typography>
                    </Box>
                ),
            },

        ],
        []
    );

    const [modalOpen, setModalOpen] = useState(false);
    const handleClickOpen = () => {

        setDocEdit([])
        setModalOpen(true);
    };



    const handleClose = () => {
        setModalOpen(false);
        setEditState(null)

    };



    const handleCloseDelete = (event, row) => {

        if (event.target.value == 'yes') {
            setDeleteState(row.index)

        }
    }



    useEffect(() => {
        setDocList([...docList]);
    }, [docList.length])

    const docsSubmit = async (data) => {
        console.log("fill online data ", data)

        setCustomerror("")
        if (editState != null) {
            docList[editState] = {
                ...docList[editState],
                ...data
            }

            setEditState(null)
        } else {
            docList.push(data);
        }

        setDocList([...docList]);

        const blankDocumentStatus = data?.blankDocument === 'Yes' ? true : false
        const apiCallData = {

            "id": data?.id,
            "taskMaster":
            {
                "id": data?.taskType,

            },
            "documentType": data?.documentType,
            "documentDescriptor": data?.documentDescriptor,
            "storageKey": uploadData?.storageKey || (blankDocumentStatus ? data?.storageKey : data?.fillableDocument ? data?.storeKeyVal : null),
            "isFillableDocument": data?.fillableDocument === 'Yes' ? true : false,
            "documentNote": data?.documentNote,
            "blankDocument": data?.blankDocument === 'Yes' ? true : false,
            "fileName": uploadData?.fileName || (blankDocumentStatus ? data?.fillableDocumentdropdown : data?.fillableDocument ?
                data?.checkeditoradd == 'add' ? data?.fillableDocumentdropdowns : data?.fillableDocumentdropdowns : null)
        };

        console.log(apiCallData, 'apiCallData')
        console.log('check123', uploadData?.fileName || (blankDocumentStatus ? data?.fileName : data?.fillableDocument == "Yes" ? data?.fileName : null), uploadData, data, (blankDocumentStatus ? data?.fileName : data?.fillableDocument ? data?.fileName : null), blankDocumentStatus)
        console.log("data", data,"viviv",data.taskType)

        try {
            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: API.createMasterTaskDoc,

                    header: docEdit?.length !== 0 ? UPDATE : CREATE,
                    refresh: updateState
                }
            )
        } catch (error) {
            console.error("API call failed", error);

        }
        handleClose()
    }

    console.log(docEdit, "docEdit");




    return (
        <>

            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box >


                    <CustomReactMaterialTable
                        staticLoad={true}
                        staticData={docListShow}
                        columns={tableColumn}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableSorting={false}
                        enableToolbarInternalActions={false}
                        renderTopToolbarCustomActions={() => (
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mr: isMobile ? '1rem' : '3rem', mt: isMobile ? '0.5rem' : '2rem' }}>
                                <Button sx={{ width: '10%' }} variant="outlined" onClick={handleClickOpen}>
                                    Add
                                </Button>
                            </Box>
                        )}
                        enableRowActions={true}
                        renderRowActions={
                            ({ row }) => {
                                console.log("rowrowrow", row, !row?.original?.fileName, row?.original?.isFillableDocument, !row?.orginal?.blankDocument,row?.original,row.index)
                                return (
                                    <Stack direction={'row'}>


                                        {/* <IconButton color='primary' onClick={previewDocument}>
                                            <KSDocumentSearchIcon />
                                        </IconButton> */}
                                        <IconButton onClick={() => { setEditState(row.index) }}>
                                            <KSPencilAltIcon />
                                        </IconButton>

                                        <IconButton
                                            color="primary"
                                            onClick={() => previewDocument(row?.original)}
                                            disabled={!row?.original?.fileName || (row?.original?.isFillableDocument && !row?.original?.blankDocument)}
                                        >
                                            <KSDocumentSearchIcon />
                                        </IconButton>

                                        {/* <ConfirmationModel
                                            body={<IconButton
                                                id={row.index} onClick={() => {
                                                    // setDeleteState(row.index)
                                                }}>
                                                <KSTrashIcon />
                                            </IconButton>}
                                            message={`Are you sure want remove  ${row?.original?.documentType} - Savings?`}
                                            title={'Delete Document'}
                                            handleSubmit={(event) => { handleCloseDelete(event, row) }}
                                        /> */}

                                    </Stack>
                                )
                            }
                        }
                    />
                    {customError && <FormHelperText error>{customError}</FormHelperText>}
                </Box>



                <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                    {/* <Stack direction={'row'} justifyContent={'space-between'}>
                        <Link to={'../borrower-info/personal-information'}>
                            <Button startIcon={<ArrowBackIos />}>
                                Previous
                            </Button>
                        </Link>
                        <Button onClick={handleAssestCreditFormSubmit} endIcon={<ArrowForwardIos />} >
                            Next
                        </Button>
                    </Stack> */}
                </Box>
            </Stack>

            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={docEdit?.length !== 0 ? `Update Task Documents` : `Add Task Documents`}
                body={
                    <DocumentLibraryForm
                        onSubmit={docsSubmit}
                        editItem={docEdit}
                        setUploadData={setUploadData}
                        hasPartner={formValue?.hasPartner}
                        handleClose={handleClose}
                        docList={taskTypeOptions}
                        fillOnlineList={fillOnlineOptions}
                        refreshStatus={refreshStatus}
                        setRefreshStatus={setRefreshStatus}
                    />
                }
            />


        </>
    )
}
