import { TabContext, TabList } from "@mui/lab";
import { Box, Button, Chip, Tab, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import LoanSummaryTaskCard from "./loan-summary-task-card";
import { DialogCommonHooks, DocumentDialogCommonHooks } from "../../../../helpers/modal/modal";
import LoanSummaryTaskForm from "../loan-summary-forms/loan-summary-task-from";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import ThirdPartTaskForm from "../loan-summary-forms/third-party-task-form";
import UploadConditionForm from "../loan-summary-forms/upload-condition-form";
import { getApiCallDynamic } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import DraftTaskCard from "./draft-task-card";
import { useRecoilValue } from "recoil";
import { draftTaskPopUpTriggerState } from '../../../../recoil/profile-atom';


export default function DraftTaskBody({
    formId,
    loanCode,
    borrowerName,
    taskTabCount,
    tasksLists,
    taskReload,
    taskTabValue,
    taskTabHandleChange,
    taskTabValueDraft,
    info
}) {







    let allTasks = [...(tasksLists || [])];
    if (taskTabValueDraft == 'Pending') {

        allTasks = allTasks.filter((val) => val.deleted != true)
    }

    console.log("allltask", tasksLists, taskTabValueDraft
    )

    const { visiblity } = useContext(LoanSummaryParentData);
    // New Task Modal
    const [taksModal, setTaksModal] = useState(false);

    const handleTaskModalOpen = () => {
        setTaksModal(true);
    };

    const handleTaskModalClose = () => {
        setTaksModal(false);
    };


    const [taskList, setTaskList] = useState([]);

    useEffect(() => {
        setTaskList(tasksLists)
    }, [])



    const [taskReceiverDetails, setTaskReceiverDetails] = useState([])
    useEffect(() => {
        // let param = { userCode: props.user.data.userCode };
        let param = { "formId": formId }

        getApiCallDynamic({ path: API.additionalContact, param: param }).then((res) => {
            if (res?.status === 200) {

                setTaskReceiverDetails(res?.data?.data)

            }
        });
    }, []);

    const draftTaskPopUpTrigger = useRecoilValue(draftTaskPopUpTriggerState);

    useEffect(() => {
        handleTaskModalClose();
        taskTabHandleChange();
    }, [draftTaskPopUpTrigger]);





    return (
        <>

            <TabContext value={taskTabValue} >

                <Box bgcolor={"white"} sx={{ borderBottom: 1, borderColor: "divider" }}>

                    <TabList
                        onChange={taskTabHandleChange}
                        variant="scrollable"
                        allowScrollButtonsMobile
                        aria-label="Loan Summary Task Tab"
                    >   {console.log(taskTabCount, 'taskTabCount')}
                        {taskTabCount?.map((tabStatus) => (
                            <Tab

                                key={tabStatus.name}
                                label={tabStatus.name}
                                value={tabStatus.name}
                                iconPosition="end"
                                icon={
                                    // Use for tab counting
                                    <Chip
                                        label={tabStatus.count}
                                        sx={{
                                            color: "black",
                                            backgroundColor:
                                                taskTabValue == tabStatus.name
                                                    ? "primarylight.main"
                                                    : "#F2F2F2",
                                            fontWeight: "bold",
                                            borderRadius: "5px",
                                        }}
                                    />
                                }
                            />
                        ))}


                    </TabList>
                </Box>
                {localStorage.getItem('uploadconditions') != null && localStorage.getItem('uploadconditions') == 'true' ?
                    <Typography
                        component={"span"}
                        color={"#ff0000"}
                        variant="body2"
                        textAlign={'center'}
                        textTransform={"capitalize"}
                    >
                        It may take a few minutes for the new draft task to appear here !
                    </Typography>
                    : null}

                {visiblity ? (
                    <Box
                        my={2}
                        display={"flex"}
                        justifyContent={{ xs: "center", md: "end" }}
                    >
                        <Button
                            variant="outlined"
                            size="small"
                            onClick={handleTaskModalOpen}
                        >{`Upload Conditions`}</Button>
                    </Box>
                ) : null}
            </TabContext>

            {console.log(allTasks, 'allTask')}
            {allTasks.map((taskData) => (

                taskData ? (

                    <DraftTaskCard
                        key={taskData.taskId}
                        formId={formId}
                        taskCardData={{
                            taskType: taskData.taskType,
                            //  taskTypeFor: taskData.taskTypeFor,
                            description: taskData.description,
                            createdTime: taskData.createdTime,
                            dueDate: taskData.dueDate,
                            taskTypeFor: taskData.taskTypeFor,
                            taskTypeId: taskData.taskTypeId,
                            editDBRowID: taskData.editDBRowID,
                            deleted: taskData.deleted,
                            taskCode: taskData.taskCode
                        }}
                        borrowerName={borrowerName}
                        taskReload={taskReload}
                        loanCode={loanCode}
                        info={info}
                        taskLists={taskList}
                        taskReceiverDetails={taskReceiverDetails}
                        allEditDataWDBID={allTasks}
                    />
                ) : null
            ))}



            <DocumentDialogCommonHooks
                title={``}
                show={taksModal}
                //  size="lg" 
                handleClose={handleTaskModalClose}
                body={
                    <UploadConditionForm
                        formId={formId}
                        loanCode={loanCode}
                        taskReload={taskReload}
                        handleClose={handleTaskModalClose}
                        taskList={taskList}
                        taskReceiverDetails={taskReceiverDetails}
                        info={info}



                    />
                }
            />
        </>
    );
}
