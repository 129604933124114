import React, { useContext, useEffect, useState } from "react";
import CustomRadioChip from "../../../../helpers/radio-chip";
import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { Controller, useForm, useWatch, } from "react-hook-form";
import {
    CONTACT_STATE,
    CREATE,
    TASK_LENDERS_CONDITION,
    TASK_PRIORITY,
    TASK_TYPE_CHOICE,
    UPDATE,
} from "../../../../constants/constatnt-variable";
import CustomInput from "../../../../helpers/custom-input";
import RadioButtonGroup from "../../../../helpers/radio-button-group-doc";
import {
    getApiCallDynamic,
    postApiCallDynamicWithOutReturn,
    uploadFile,
} from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import {
    DateToTimestapmFormater,
    TimeStampToMonthDateYearForInput,
} from "../../../../helpers/time-formater";
import { currentDate } from "../../../../helpers/common_fuctions/current-date";
import { KSCloudUploadIcon } from "../../../../icons/custome-icon";
import { toastNotify } from "../../../../helpers/alert-msg";
import { TaskTypeValidation, TaskValidation } from "../../../../constants/validation-rules";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import MultiSelectDropDown from "../../../../helpers/dropdown/multi-select-dropdown";


import SupportingDocument from "./supporting-doc";
import PreviewTask from "./preview-task";
import { DocumentDialogCommonHooks } from "../../../../helpers/modal/modal";
import CustomInputMultiLine from "../../../../helpers/custom-input-multiline";
import { TaskContext } from "../../taskContext/TaskContext";







export default function BorrowerTaskForm({
    formId,
    loanCode,
    edit = false,
    taskFormData,
    handleClose,
    taskReload,
    taskList,  //Borrower Master Tasks
    taskReceiverDetails,
    info,
    isDraftedTask,
    taskId,
    taskTypeId,
    editDBRowID,
    profileInfo,
    description,
    taskCode
}) {

    const {
        control,
        handleSubmit,
        formState: { errors }, setValue, getValues
    } = useForm({
        defaultValues: {
            taskType: ""
        },
    });
    console.log("task iddd ", taskId, taskTypeId, taskList)
    const [apiCallData, setApiCallData] = useState(null);

    const { taskState, updateContextBorrowerTasks, updateContextThirdPartyTasks,

        insertSupportingDoc,
        deleteSupportingDoc,
        updateSupportingDoc,
        clearSupportingDocs,

    } = useContext(TaskContext);
    let { contextSupportingDocs } = taskState;
    console.log(contextSupportingDocs, 'TaskContext')
    const [selectIDfromDropdown, setselectIDfromDropdown] = useState(0);
    contextSupportingDocs = contextSupportingDocs.map(doc => ({ ...doc, docId: doc.id }));

    // console.log("contextSupportingDocscontextSupportingDocs", contextSupportingDocs.docId = contextSupportingDocs.id)
    // console.log("taskStatetaskStatetaskState", taskState)
    // console.log("TaskContextTaskContextTaskContext", TaskContext)

    console.log(taskList, 'taskTypeIdtaskTypeId')
    console.log("task iddd ", taskId, taskTypeId)

    let taskTypeOptions;
    if (taskTypeId != undefined) {
        console.log('hiop')
        taskTypeOptions = taskList.map((task) => (
            {
                label: task?.taskTypeName,
                dbValue: task?.id,
                value: task?.id
            }
        ));
    } else {
        taskTypeOptions = taskList.map((task) => ({
            label: task?.taskTypeName,
            value: task?.id,
            dbValue: task?.taskTypeId
        }));
    }
    console.log(taskTypeOptions, 'taskTypeOptions')
    const [taskTypeTitle, setTaskTypeTitle] = useState(null)
    const getTaskTypeNameById = (id) => {
        const task = taskList.find((task) => task.id === id);
        return task ? task.taskTypeName : null;
    };


    const { taskType, taskReceiver } = useWatch({ control })

    useEffect(() => {

        if (taskType) {



            setTaskTypeTitle(getTaskTypeNameById(taskType))

        }
    }, [taskType]);








    const [titles, setTitles] = useState([])
    const [taskDocs, setTaskDocs] = useState([])
    const [requiredDocs, setRequiredDocs] = useState([])
    const [supportingDocs, setSupportingDocs] = useState([])
    const [selectedReqDocs, setSelectedreqDocs] = useState([])
    const [matchedReqDocs, setMatchedReqDocs] = useState(null)

    useEffect(() => {

        if (taskType) {
            let path
            if (taskTypeId != undefined) {
                path = API.getMasterTaskDocByTaskID() + taskType
            } else {
                path = API.getMasterTaskDocByTaskID() + taskType
            }

            const taskListsData = () => {


                getApiCallDynamic({

                    path


                })
                    .then((res) => {
                        if (res?.status === 200) {
                            const tasks = res?.data || [];
                            setTaskDocs(tasks);


                            setRequiredDocs(res.data)

                            const titles = res?.data
                                .filter(item => item.documentType !== undefined)
                                .map(item => item.documentType);


                            setTitles(titles);


                        }
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });
            };

            taskListsData();

            handleSelectAllChange({ target: { checked: false } });


        }
    }, [taskType]);





    const formOnSubmit = async (data) => {





        const requiredDocuments = requiredDocs.filter(doc =>
            data?.requiredDocs?.includes(doc.documentType))
            .map(({ id, storageKey, fileName, ...rest }) => ({
                ...rest,
                blankDocStorageKey: storageKey,
                blankDocFileName: fileName,
                blankDocId: id
            }));


        const apiData = {
            "id": editDBRowID,
            "taskCode": null,
            "taskTypeName": taskTypeTitle,
            "loanCode": loanCode,
            "formId": formId,
            "taskType": {
                "id": data?.taskType,

            },
            "taskDescription": data?.taskDescription,
            "dueDate": data?.dueDate,
            "taskPriority": data?.taskPriority,
            "lenderCondition": data?.isLender === 'Yes' ? true : false,

            requiredDocuments,

            supportingSampleDocuments: contextSupportingDocs,

            "taskStatus": null,
            "isDraftedTask": isDraftedTask


        }


        setApiCallData(apiData);

        await postApiCallDynamicWithOutReturn({
            data: apiData,
            path: API.getThirdPartyTask,
            header: edit ? UPDATE : CREATE,
            refresh: taskReload,
        });

        handleClose();
    };

    const [finalClick, setFinalClick] = useState(false);

    const [formData, setFormData] = useState({});
    const { watch } = useForm();



    const handlePreview = (data) => {
        handleSubmit((data) => {


            const requiredDocuments = requiredDocs.filter(doc =>
                data?.requiredDocs?.includes(doc.documentType))
                .map(({ id, storageKey, fileName, ...rest }) => ({
                    ...rest,
                    blankDocStorageKey: storageKey,
                    blankDocFileName: fileName,
                    blankDocId: id
                }));
            console.log("requiredDocumentsrequiredDocuments", requiredDocuments)

            const apiData = {
                //  "id": 123,
                "taskCode": null,
                "taskTypeName": taskTypeTitle,
                "loanCode": null,
                "formId": formId,
                "taskType": {
                    "id": taskTypeId != undefined ? selectIDfromDropdown : data?.taskType,

                },
                "taskDescription": data?.taskDescription,
                "dueDate": data?.dueDate,
                "taskPriority": data?.taskPriority,
                "lenderCondition": data?.isLender === 'Yes' ? true : false,

                requiredDocuments,

                supportingSampleDocuments: contextSupportingDocs,

                "taskStatus": null,
                "isDraftedTask": isDraftedTask ? true : false

            }


            setApiCallData(apiData);



            setModalOpen(true);


            setIsPreview(true);


        })();
    };

    const handleButtonClick = () => {
        setFinalClick(true);
    };


    const [isPreview, setIsPreview] = useState(false);
    const handleSend = () => {

        handleSubmit(formOnSubmit)();
    };
    const handleClosePreview = () => {
        setModalOpen(false);
    };
    const handleCancelPreview = () => {
        setIsPreview(false);
    };





    const [modalOpen, setModalOpen] = useState(false);




    const [selectAll, setSelectAll] = useState(false);



    const handleSelectAllChange = (event) => {
        const isChecked = event.target.checked;
        setSelectAll(isChecked);
        const selectedOptions = isChecked ? titles : [];
        setValue('requiredDocs', selectedOptions);
    };

    useEffect(() => {
        // if (taskTypeId && taskTypeOptions.length > 0) {
        console.log(taskTypeOptions, 'taskTypetaskTypetaskType')
        const matchedOption = taskTypeOptions.find(option => option?.value === taskTypeId);
        // if (matchedOption) {
        if (taskTypeId != undefined) {
            setValue('taskType', matchedOption?.value);

        }

        if (description != undefined || description != null) {
            setValue('taskDescription', description);
        }

        // setValue('taskType', matchedOption?.dbValue);
        setselectIDfromDropdown(matchedOption?.dbValue)
        setTaskTypeTitle(matchedOption?.label);
        console.log(matchedOption, 'getFiltervalue', matchedOption?.dbValue)
        //     }
        // }
    }, []);


    useEffect(() => {

        if (selectedReqDocs && selectedReqDocs.length > 0) {
            // Find matched required documents based on selected types
            const matchedDocs = requiredDocs.filter(doc => selectedReqDocs.includes(doc.documentType));
            setMatchedReqDocs(matchedDocs);

            // Clear existing supporting documents
            clearSupportingDocs();

            // Filter and format supporting documents that are blank or fillable
            const supportingDocs = matchedDocs
                .filter(doc => doc.blankDocument === true || doc.isFillableDocument === true || (doc.blankDocument === true && doc.isFillableDocument === true))
                .map(({ documentType, ...rest }) => ({
                    ...rest,
                    documentName: documentType,
                }));

            // Insert the new supporting documents
            if (supportingDocs.length > 0) {
                supportingDocs.forEach(doc => insertSupportingDoc(doc));
            }

        }
        else if (selectedReqDocs.length !== 0) {
            clearSupportingDocs()
        }
    }, [selectedReqDocs]);


    // useEffect(() => {
    //     console.log(taskTypeOptions, 'taskTypeOptionstaskTypeOptions', taskType)
    //     setValue('taskType', taskType)
    // }, [taskTypeOptions])

    return (
        <>







            {!isPreview ? (
                <Box >

                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
                        <Grid container spacing={2} justifyContent="center">

                            <Grid item xs={12} md={12}>

                                <form onSubmit={handleSubmit(formOnSubmit)}>

                                    <Typography variant="h3" paragraph>Create New Task</Typography>


                                    {console.log("taskdatadropdown", taskTypeOptions)}
                                    <Controller
                                        name='taskType'
                                        control={control}
                                        rules={TaskTypeValidation.taskType}

                                        render={({ field }) => (

                                            <CustomDropDown
                                                {...field}
                                                field={field}
                                                label={'Task Type'}
                                                required
                                                state={field.value}

                                                option={taskTypeOptions}
                                                error={errors?.taskType?.message}
                                                value={field.value || ""}

                                            />
                                        )}
                                    />

                                    <Controller
                                        name="taskDescription"
                                        control={control}

                                        rules={{ required: "Task description is required" }}
                                        render={({ field, fieldState: { error } }) => (
                                            <CustomInputMultiLine

                                                {...field}
                                                label="Enter Task Description"
                                                multiline
                                                error={error?.message}
                                            />
                                        )}
                                    />


                                    {/* <Controller
                                name="taskDescription"
                                control={control}
                                rules={{ required: "Task description is required" }}
                                render={({ field, fieldState: { error } }) => (
                                    <CustomInputMultiLine
                                        {...field}
                                        label="Enter Task Description"
                                        multiline
                                        error={error?.message}
                                    />
                                )}
                            /> */}





                                    <Box marginY={4}>

                                        <Typography variant="h3" paragraph>Required Document</Typography>
                                        <Stack direction="column" spacing={2}>
                                            <Box display="flex" justifyContent="flex-end">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={selectAll}
                                                            onChange={handleSelectAllChange}
                                                        />
                                                    }
                                                    label="Select All"
                                                />
                                            </Box>
                                        </Stack>
                                        {/* <Controller
                                            name='requiredDocs'
                                            control={control}
                                            render={({ field }) => (
                                                <MultiSelectDropDown
                                                    field={field}

                                                    options={titles}
                                                    selectAll={selectAll}
                                                    errors={errors.requiredDocs}
                                                //label="Tag"
                                                />
                                            )}
                                        /> */}
                                        <Controller
                                            name='requiredDocs'
                                            control={control}
                                            render={({ field }) => (
                                                <MultiSelectDropDown
                                                    field={field}
                                                    options={titles}
                                                    selectAll={selectAll}
                                                    changeCallback={(selected) => {
                                                        setSelectedreqDocs(selected);

                                                    }}
                                                    errors={errors.requiredDocs}
                                                />
                                            )}
                                        />


                                    </Box>






                                    <Controller
                                        name="dueDate"
                                        control={control}
                                        rules={TaskValidation.dueDate}
                                        render={({ field }) => (
                                            <CustomInput
                                                {...field}
                                                field={field}
                                                type={"date"}
                                                // maxDate={currentDate()}
                                                label={"Due date"}
                                                required
                                                error={errors?.dueDate?.message}
                                            />
                                        )}
                                    />







                                    <Controller
                                        name="taskPriority"
                                        control={control}
                                        rules={TaskValidation.taskPriority}
                                        render={({ field }) => (
                                            <CustomRadioChip
                                                {...field}
                                                field={field}
                                                required
                                                label={"Task priority"}
                                                data={TASK_PRIORITY}
                                                error={errors?.taskPriority?.message}

                                            />
                                        )}
                                    />





                                    <Controller
                                        name="isLender"
                                        control={control}
                                        rules={TaskValidation.isLender}
                                        render={({ field }) => (
                                            <RadioButtonGroup
                                                {...field}
                                                field={field}
                                                required
                                                direction="row"
                                                labelComponent={<FormLabel>{`Is Lender's Condition?`}</FormLabel>}
                                                data={TASK_LENDERS_CONDITION}
                                                error={errors?.isLender?.message}
                                            />
                                        )}
                                    />





                                    <Grid container>
                                        <Grid item xs={12} md={12} marginY={4}>
                                            <Typography variant="h3" paragraph>
                                                Supporting Documents
                                            </Typography>
                                            {console.log("supporting documents :", contextSupportingDocs)}
                                            <SupportingDocument
                                                supportingDocs={contextSupportingDocs}
                                                setSupportingDocs={setSupportingDocs}
                                                formId={formId}
                                                loanCode={loanCode}
                                                documentTypes={titles}
                                                profileInfo={profileInfo}
                                                taskCode={taskCode}
                                            />
                                        </Grid>
                                    </Grid>



                                    <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2}>
                                        <Button
                                            variant="contained"
                                            onClick={() => handlePreview(getValues())}
                                            disabled={false}
                                        >
                                            Preview
                                        </Button>
                                        <Button variant="text" onClick={handleClose}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </form>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            ) : (


                <DocumentDialogCommonHooks
                    show={modalOpen}
                    handleClose={handleCancelPreview}
                    // title={`Add Task Type`}
                    size={'lg'}
                    body={
                        <PreviewTask
                            isPreview={true}
                            formData={apiCallData}
                            handleSend={handleSend}
                            handleCancelPreview={handleCancelPreview}
                            info={info}
                            contextSupportingDocs={contextSupportingDocs}
                        />
                    }
                />



            )}



        </>
    );
}