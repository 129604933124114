import {
    AccessTime,
    EditOutlined,
    Handshake,
    SwapVertOutlined,
} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Grid,
    IconButton,
    Stack,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import {
    UPDATE,
} from "../../../../constants/constatnt-variable";
import React, { useContext, useEffect, useState } from "react";
import { KSUploadIcon } from "../../../../icons/custome-icon";
import { DialogCommonHooks, DocumentDialogCommonHooks, DocumentDialogCommonHooksTask } from "../../../../helpers/modal/modal";
import BorrowerTaskForm from "../loan-summary-forms/borrower-task-form";
import { TimeStampToDateMonth } from "../../../../helpers/time-formater";
import { LoanSummaryParentData } from "../loan-summary-visiblity";
import { TaskContext } from "../../taskContext/TaskContext";
import { postApiCallDynamicWithParams, getApiCallDynamic } from "../../../../services/api-service";
import { API } from "../../../../services/api-config";
import ThirdPartTaskForm from "../loan-summary-forms/third-party-task-form";
import { useSetRecoilState } from 'recoil';
import { deleteDraftTaskTriggerState } from '../../../../recoil/profile-atom';


export default function DraftTaskCard({
    formId,
    taskCardData,
    borrowerName,
    taskReload,
    loanCode,
    info,
    taskLists,
    taskReceiverDetails,
    allEditDataWDBID,
    profileInfo


}) {
    const { visiblity } = useContext(LoanSummaryParentData);
    const { clearSupportingDocs } = useContext(TaskContext);
    console.log("filteredTasks ", taskCardData);
    const editVisible =
        taskCardData.taskStatus !== "Completed" && visiblity

    // New Task Modal
    const [taksModal, setTaksModal] = useState(false);
    // PS_46	useState(taskList)	Initialize state variables for task modal and list
    const [taskList, setTaskList] = useState([]);
    const [borrowerTaskList, setBorrowerTaskList] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteResponse, setDeleteResponse] = useState(false);



    // PS_48	Delete Dialog	Manage open / close states for delete confirmation dialog
    const handleTaskModalOpen = () => {
        setTaksModal(true);
    };

    const handleTaskModalClose = () => {
        clearSupportingDocs()
        setTaksModal(false);
    };

    // Changing card icon color based on Task type
    let cardIcon, iconBG;
    switch (taskCardData.taskType) {
        case "Download & upload":
            cardIcon = <SwapVertOutlined />;
            iconBG = "#FBAAA7";

            break;

        case "External":
            cardIcon = <Handshake />;
            iconBG = "#B880FF";
            break;

        case "Upload":
            cardIcon = <KSUploadIcon />;
            iconBG = "#8EE1E7";
            break;

        default:
            break;
    }
    const handleDeleteDialogOpen = () => {
        setDeleteDialogOpen(true);
    };
    // PS_47	Create function named handleDeleteDialogClose to open / close task edit modal
    const handleDeleteDialogClose = () => {
        setDeleteDialogOpen(false);
    };

    useEffect(() => {
        const taskListsData = () => {
            getApiCallDynamic({
                path: API.getMasterTask,
            }).then((res) => {
                let filteredTasks;
                if (res?.status === 200) {
                    // if (taskCardData.taskTypeFor === "thirdparty") {
                    filteredTasks = res?.data.filter(task => task.taskTypeFor === "thirdparty");
                    console.log("thirdpartyfilterdata", filteredTasks)

                    filteredTasks.forEach(itemA => {
                        console.log("thirdpartyfilterdatafor", filteredTasks)
                        let matchingItemB = allEditDataWDBID.find(itemB => itemB.taskType === itemA.taskTypeName);
                        if (matchingItemB) {
                            itemA.taskTypeId = matchingItemB.taskTypeId;
                        }
                    });


                    console.log(filteredTasks, 'filteredTasks')
                    setTaskList(filteredTasks);
                    // } else {
                    filteredTasks = res?.data.filter(task => task.taskTypeFor === "borrower");
                    console.log("borrowerfilterdata", filteredTasks)
                    filteredTasks.forEach(itemA => {
                        let matchingItemB = allEditDataWDBID.find(itemB => itemB.taskType === itemA.taskTypeName);
                        if (matchingItemB) {
                            itemA.taskTypeId = matchingItemB.taskTypeId;
                        }
                    });


                    console.log(filteredTasks, 'filteredTasks')
                    setBorrowerTaskList(filteredTasks);
                    // }
                }
            }).catch((error) => {
                console.error('Error fetching data:', error);
            });
        };

        taskListsData();
    }, []); // Empty dependency array to run only once

    const deleteDraftTaskTrigger = useSetRecoilState(deleteDraftTaskTriggerState);
    const handleDeleteConfirm = async () => {
        // logic for delete action
        const param = {
            formId: formId,
            taskId: taskCardData.editDBRowID,
            taskTypeName: taskCardData.taskType
        }
        console.log("params", param);
        setDeleteDialogOpen(false);
        try {


            const res = await postApiCallDynamicWithParams({
                path: API.removeDraftTask,
                params: param,
                header: UPDATE,
                refresh: () => { deleteDraftTaskTrigger(prev => prev + 1); }
            });


        } catch (error) {
            console.error("Error during OTP validation:", error);
            throw error;
        }

    };

    return (
        <>
            <Box bgcolor={"white"} borderRadius={3} p={2} my={{ xs: 2, md: 2 }}>
                {/* Main Frame of Card Start*/}
                <Stack direction={"row"} gap={{ xs: 1, md: 3 }}>
                    {/* Card Icon */}
                    <Avatar sx={{ bgcolor: iconBG }}>{cardIcon}</Avatar>

                    <Grid container spacing={{ xs: 2, md: 1 }}>
                        {/* Card Title with Edit Icon  Start*/}
                        <Grid item xs={12} md={8} lg={9}>
                            <Typography variant="h5">
                                {`${taskCardData.taskType} `}
                                <Box component={"span"} mx={1}>

                                    <Typography
                                        component={"span"}
                                        color={"primary"}
                                        variant="body2"
                                        textTransform={"capitalize"}
                                    >
                                        {taskCardData.priority}
                                    </Typography>
                                </Box>
                            </Typography>
                        </Grid>
                        {/* Card Title with Edit Icon End*/}

                        {/* Card Created and Due Date Start */}
                        <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            textAlign={{ xs: "left", md: "right" }}
                        >
                            <Typography variant="body2">{`Created on: ${TimeStampToDateMonth(
                                taskCardData.createdTime
                            )}`}</Typography>
                            <Typography
                                variant="body2"
                                color={"warning.main"}
                            >{`Due on: ${TimeStampToDateMonth(
                                taskCardData.dueDate
                            )}`}</Typography>
                        </Grid>
                        {/* Card Created and Due Date End */}

                        {/* Card Descripton Start */}
                        {/* <Grid item xs={12} md={8} lg={9}>
                            <Typography variant="body1" textAlign={"justify"} sx={{
                                maxWidth: '50%',
                                wordBreak: 'break-word',
                            }}>
                                {taskCardData.description}
                            </Typography>
                        </Grid> */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Typography
                                variant="body1"
                                textAlign={"justify"}
                                sx={{
                                    maxWidth: '100%', // Adjusted to ensure it fits the available space
                                    wordBreak: 'break-word',
                                    overflow: 'hidden', // Ensures content doesn't overflow
                                    textOverflow: 'ellipsis', // Adds ellipsis for overflowed text
                                    whiteSpace: 'normal', // Allows the text to wrap
                                    paddingBottom: '20px', // Optional: Add padding for better spacing
                                }}
                            >
                                {taskCardData.description}
                            </Typography>
                        </Grid>

                        {/* Card Descripton End */}

                        {/* Card Status and resolve Start */}
                        <Grid item xs={12} md={8} lg={9}>
                            <Stack direction={"row"} gap={{ xs: 1, md: 2 }} flexWrap={"wrap"}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleDeleteDialogOpen}
                                    disabled={taskCardData.deleted === true}
                                >
                                    Delete
                                </Button>
                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {

                                        handleTaskModalOpen();
                                    }}
                                    disabled={taskCardData.deleted === true}
                                >
                                    Edit
                                </Button>
                            </Stack>
                        </Grid>
                        {/* Card Status and resolve End */}

                        {/* Card Status Icon Start */}
                        {/* <Grid
                            item
                            xs={12}
                            md={4}
                            lg={3}
                            py={{ xs: 1, md: 0 }}
                            alignSelf={"end"}
                        >
                            {taskCardData.taskStatus !== "Active" ? null : (
                                <Stack
                                    direction={"row"}
                                    gap={1}
                                    alignItems={"center"}
                                    justifyContent={{ xs: "start", md: "end" }}
                                >
                                    <AccessTime color="warning" sx={{ fontSize: "18px" }} />
                                    <Typography variant="body1" color={"warning.main"}>
                                        {`Pending`}
                                    </Typography>

                                </Stack>
                            )}
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={handleResolveTaskThirdPartyModalOpen}
                            >{`Resolve`}</Button>
                        </Grid> */}
                        {/* Card Status Icon End */}
                    </Grid>
                </Stack>
            </Box>
            {/* Main Frame of Card End */}

            <DocumentDialogCommonHooksTask
                title={``}
                show={taksModal}
                //  size="lg" 
                handleClose={handleTaskModalClose}
                body={
                    <>
                        {taskCardData.taskTypeFor === "thirdparty" ? (
                            <ThirdPartTaskForm
                                formId={formId}
                                loanCode={loanCode}
                                taskReload={taskReload}
                                handleClose={handleTaskModalClose}
                                taskList={taskList}
                                taskReceiverDetails={taskReceiverDetails}
                                info={info}
                                isDraftedTask={true}
                                taskId={taskCardData.taskTypeId}
                                taskTypeId={taskCardData.taskTypeId}
                                editDBRowID={taskCardData.editDBRowID}
                                profileInfo={profileInfo}
                                description={taskCardData.description}
                                taskCode={taskCardData.taskCode}

                            />
                        ) : (
                            <BorrowerTaskForm
                                formId={formId}
                                loanCode={loanCode}
                                taskReload={taskReload}
                                handleClose={handleTaskModalClose}
                                taskList={borrowerTaskList} // Borrower Master Tasks
                                info={info}
                                isDraftedTask={true}
                                taskId={taskCardData.taskTypeId}
                                taskTypeId={taskCardData.taskTypeId}
                                editDBRowID={taskCardData.editDBRowID}
                                profileInfo={profileInfo}
                                description={taskCardData.description}
                                taskCode={taskCardData.taskCode}
                            />
                        )}
                    </>
                }
            />
            {/* <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this task?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteDialogClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog> */}
            <DialogCommonHooks
                title={`Confirm Delete`}
                onlyCloseIcon={true}
                handleClose={handleDeleteDialogClose}
                show={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                body={
                    <Box p={3}>
                        <Typography variant="body1" paragraph>
                            Are you sure you want to delete this task?
                        </Typography>

                        <Stack direction={{ xs: "column", md: "row-reverse" }} gap={2} mt={5} mb={5}>
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={handleDeleteConfirm}
                            >
                                Yes
                            </Button>
                            <Button variant="text" onClick={handleDeleteDialogClose}>
                                No
                            </Button>
                        </Stack>
                    </Box>
                }
            />
        </>
    );
}
