

const DOCUMENT_URL_MAP = {

    'gift letter': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/giftletter/gift_letter.html`,
    'giftletter': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/giftletter/gift_letter.html`,
    // 'addressloe': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/addressLOE/Address_LOE.html`,
    'addressloe': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/addressLOE/Address_LOE.html`,
    'altasettlement': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/ALTASettlementStatement/ALTA_Settlement_Statement_Combined.html`,
    'altasettlementstatement': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/ALTASettlementStatement/ALTA_Settlement_Statement_Combined.html`,
    'borrowercertificationandauth': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/borrowerCertificationAndAurthorization/Borrower-Certifcate-Authorization.html`,
    'floodcert': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/floodCert/Flood_Certificate.html`,
    'floodcertificate': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/floodCert/Flood_Certificate.html`,
    'itemizedfeeworksheet': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/itemizedFeeWorksheet/itemized-fee-worksheet.html`,
    'requestfortitlecommitment': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/requestForTitleCommitment/Request_For_Title_Commitment.html`,
    'requestfortranscriptoftax': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/requestForTranscriptOfTax/Request_For_Transcript_Of_Tax.html`,
    'verificationofemployment': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/verificationOfEmployment/Verification_Of_Employment.html`,

    // changes document type 

    'loe': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/addressLOE/Address_LOE.html`,
    'settlement statement': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/ALTASettlementStatement/ALTA_Settlement_Statement_Combined.html`,
    'borrower authorization form': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/borrowerCertificationAndAurthorization/Borrower-Certifcate-Authorization.html`,
    'flood certificate': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/floodCert/Flood_Certificate.html`,
    'loan estimate': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/itemizedFeeWorksheet/itemized-fee-worksheet.html`,
    'title commitment': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/requestForTitleCommitment/Request_For_Title_Commitment.html`,
    '4506-c - irs transcripts': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/requestForTranscriptOfTax/Request_For_Transcript_Of_Tax.html`,
    'written verification of employment': `${process.env.REACT_APP_FILLONLINE_BASE_URL}/forms/verificationOfEmployment/Verification_Of_Employment.html`
};

export const getDocumentUrl = (documentType) => {
    const baseUrl = DOCUMENT_URL_MAP[documentType];

    if (!baseUrl) {
        console.warn(`No URL mapping found for document type: ${documentType}`);
        return null;
    }

    return baseUrl;
};

// Optional: Helper function to handle the complete URL with query params
// export const getCompleteDocumentUrl = (documentType, queryParams) => {
//     console.log("docuement type", documentType);

//     const baseUrl = getDocumentUrl(documentType?.documentName.toLowerCase());

//     if (!baseUrl) {
//         return null;
//     }

//     if (queryParams) {
//         return `${baseUrl}?${queryParams}`;
//     }

//     return baseUrl;
// };


export const getCompleteDocumentUrl = (documentType, queryParams) => {
    console.log("document type", documentType);

    const baseUrl = getDocumentUrl(documentType?.documentName.toLowerCase());

    if (!baseUrl) {
        return null;
    }

    console.log((queryParams), 'baseUrlbaseUrl')
    // history.push(`/destination-page?${params.toString()}`);

    if (queryParams) {
        // Encode the query parameters before appending them to the URL
        // const encodedParams = Object.keys(queryParams)
        //     .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
        //     .join('&');
        // console.log("encodedParamsencodedParams", encodedParams)
        return `${baseUrl}?${'form=' + btoa(queryParams)}`;
    }
    return baseUrl;
};

