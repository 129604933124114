import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, Button, FormHelperText, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { DialogCommonHooks, DocumentDialogCommonHooks } from '../../../../helpers/modal/modal';
import CustomReactMaterialTable from '../../../../helpers/table/react-material-table';
import { API } from '../../../../services/api-config';
import ConfirmationModel from '../../../../helpers/modal/confirm-modal';
import { KSDocumentSearchIcon, KSPencilAltIcon, KSTrashIcon } from '../../../../icons/custome-icon';
import { getApiCallDynamic } from '../../../../services/api-service';
import { kstheme } from '../../../../theme/theme';
import SupportingDocumentForm from './supporting-doc-form';
import { previewDocument } from '../../../../helpers/preview-document';
import { TaskContext } from '../../taskContext/TaskContext';
import { formState, profileState } from '../../../../recoil/profile-atom';
import { useRecoilState, useRecoilValue } from 'recoil';
import IframeModal from '../../../../pages/third-party/iframeModal';
import { getCompleteDocumentUrl } from '../../../../helpers/common_fuctions/fill_online';
import { toastNotify } from "../../../../helpers/alert-msg";

export default function SupportingDocument({ supportingDocs, setSupportingDocs, formId, loanCode, documentTypes, profileInfo, taskCode }) {
    const { taskState, insertSupportingDoc, deleteSupportingDoc } = useContext(TaskContext);
    let { borrowerTask, contextSupportingDocs } = taskState;
    const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
    const route = useNavigate();
    const location = useLocation();
    const [docList, setDocList] = useState(contextSupportingDocs);
    const [localData, setLocalData] = useState(null);
    const [showIframe, setShowIframe] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [iframeUrl, setIframeUrl] = useState("");
    const [deleteState, setDeleteState] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [docEdit, setDocEdit] = useState(null);
    const [docListLibrary, setDocListLibrary] = useState([]);
    const [completedLoanDocs, setCompletedLoanDocs] = useState([]);
    let [mainForm, setMainForm] = useRecoilState(formState);
    const [documentData, setDocumentData] = useState({
        blanckDocId: '',
        filename: '',
        docId: '',
        storageKey: '',
        closeStatus: false,
        fillOnlineDocId: '',
        jsonStorageKey: ''
    });

    //console.log("checking profile info" + profileInfo.role);

    // Line added to resolve role null issue
    profileInfo = useRecoilValue(profileState);


    console.log("taskStatetaskStatetaskStatetaskStatetaskStatetaskStatetaskStatetaskStatetaskState", taskState)
    useEffect(() => {
        if (localData !== null) {
            if (Array.isArray(localData)) {
                localData.forEach(doc => insertSupportingDoc(doc));
                console.log("iflocalDatalocalDatalocalDatalocalDatalocalDatalocalData", localData);
            } else {
                insertSupportingDoc(localData);
                console.log("localDatalocalDatalocalDatalocalDatalocalDatalocalData", localData);

            }
            setLocalData(null);
        }
    }, [localData]);
    console.log("outlocalDatalocalDatalocalDatalocalDatalocalDatalocalData", localData);


    useEffect(() => {
        if (deleteState !== null) {
            const updatedDocList = supportingDocs.filter((_, index) => index !== deleteState);
            setDocList(updatedDocList);
            setSupportingDocs(updatedDocList);
            setDeleteState(null);
        }
    }, [deleteState]);

    useEffect(() => {
        const fetchMasterDocuments = async () => {
            try {
                const res = await getApiCallDynamic({ path: API.getMasterTaskDoc });
                if (res?.status === 200) {
                    setDocListLibrary(res.data);
                    console.log("reuereeeeeeeee", res.data);

                }
            } catch (error) {
                console.error('Error fetching master task documents:', error);
            }
        };
        fetchMasterDocuments();
    }, []);

    useEffect(() => {
        const fetchLoanDocuments = async () => {
            try {
                const res = await getApiCallDynamic({ path: API.getLoanDocuments, param: { formId, loanCode } });
                if (res?.status === 200) {
                    setCompletedLoanDocs(res.data);
                }
            } catch (error) {
                console.error('Error fetching loan documents:', error);
            }
        };
        fetchLoanDocuments();
    }, [formId, loanCode]);


    const handleFillFormClick = (document) => {
        console.log("Clicked Document:", document);
        console.log("role role", profileInfo);

        setSelectedRowData(document);
        const iframeData = {
            formId,
            blankdocId: document.id,
            documentType: document.fileName,
            taskType: document.taskMaster?.taskTypeName || '',
            role: profileInfo?.role.toLowerCase() || '',
            docId: document?.fillOnlineDocId || '',
            taskCode: taskCode,
            BASE_URL: process.env.REACT_APP_BASE_URL

        };
        console.log("iframe data ::", iframeData)
        const queryParams = new URLSearchParams(iframeData).toString();
        const url = getCompleteDocumentUrl(document, queryParams);
        console.log("url for the iframe : ", url)
        setIframeUrl(url);
        setShowIframe(true);
    };

    const tableColumn = useMemo(() => [
        {
            accessorKey: 'fileName',
            header: 'Document Name',
            enableColumnActions: false,
            Cell: ({ row }) => {
                const blankDocument = row?.original['blankDocument'];
                const fillDocument = row?.original['isFillableDocument'];

                console.log("fillDocumentfillDocumentfillDocument", fillDocument)
                console.log("rowrowrowrowrow", row)
                return (
                    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '300px' }}>
                        <Typography variant="body1">{row?.original['fileName']}</Typography>
                        {!blankDocument && fillDocument && (
                            <Box>

                                <Typography
                                    variant="body2"
                                    // sx={{
                                    //     color: formData.taskStatus === 'RESOLVED' ? '#B0B0B0' : '#D94166', // Change color if disabled
                                    //     cursor: formData.taskStatus === 'RESOLVED' ? 'not-allowed' : 'pointer', // Change cursor if disabled
                                    //     pointerEvents: formData.taskStatus === 'RESOLVED' ? 'none' : 'auto' // Disable pointer events if resolved
                                    // }}
                                    sx={{ color: '#3498db', cursor: 'pointer' }}
                                    onClick={() => handleFillFormClick(row.original)}
                                >
                                    fill form
                                </Typography>
                            </Box>
                        )}
                        {blankDocument && fillDocument && (
                            <>
                                <Typography variant="body2" sx={{ color: '#D94166' }}>
                                    blank form
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{ color: '#3498db', cursor: 'pointer' }}
                                    onClick={() => handleFillFormClick(row.original)}
                                >
                                    fill form
                                </Typography>
                            </>
                        )}
                        {blankDocument && !fillDocument && (
                            <Typography variant="body2" sx={{ color: '#D94166' }}>
                                blank form
                            </Typography>
                        )}


                    </Box>
                );
            },
        },
        {
            accessorKey: 'documentName',
            header: 'Document Type',
            enableColumnActions: false,
        },
    ], []);

    useEffect(() => {
        // Function to handle messages received from the iframe
        const handleMessage = (event) => {
            console.log("Message received from iframe:", event);

            // Validate the origin - Replace 'http://localhost:3000' with your actual origin in production
            const allowedOrigin = 'https://fillonline.kramasoft.com';
            if (event.origin !== allowedOrigin) {
                console.warn("Message from unauthorized origin:", event.origin);
                return;
            }

            let data;
            // Check if event.data is a string or an object
            if (typeof event.data === 'string') {
                try {
                    data = JSON.parse(event.data);  // Attempt to parse JSON
                    console.log("eventdatastring", data);
                } catch (error) {
                    console.error("Failed to parse event data:", error);
                    return;  // Exit if parsing fails
                }
            } else if (typeof event.data === 'object') {
                data = event.data;  // Use directly if it's already an object
                console.log("eventdataobject", event.data);
            } else {
                console.warn("Unexpected message format:", event.data);
                return;
            }

            console.log("outdata", data)
            // Update state with the received data
            setDocumentData(prevState => ({
                ...prevState, // Keep the previous state intact
                blankDocId: data.blankDocId || prevState.blankDocId,
                fillOnlineDocId: data?.docStorageId != null ? data.docStorageId : prevState.fillOnlineDocId,
                fileName: data?.fileName || prevState.fileName,
                docId: data?.docId || prevState.docId,
                storageKey: data?.storageKey || prevState.storageKey,
                closeStatus: data?.closeStatus || prevState.closeStatus,
                jsonStorageKey: data.jsonStorageKey
            }));
        };

        // Attach event listener to window object
        window.addEventListener('message', handleMessage);
        console.log("handlemessage", handleMessage);
        console.log("document data ", documentData);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    });  // Empty dependency array to ensure it runs only once on mount
    taskState.contextSupportingDocs = taskState.contextSupportingDocs.map(doc => {
        console.log("Current doc:", doc);
        console.log("documentData.blankdocid:", documentData.blankDocId);
        console.log("Condition check:", doc.id == documentData.blankDocId);

        return doc.id == documentData.blankDocId
            ? { ...doc, fillOnlineDocId: documentData.fillOnlineDocId } // ✅ Correct: Returning the new modified object
            : doc; // ✅ Correct: Returning the original object if condition is false
    });

    useEffect(() => {
        if (documentData.closeStatus) {
            setShowIframe(false)
            if (documentData.jsonStorageKey != null && documentData.jsonStorageKey != undefined && documentData.jsonStorageKey != '') {
                console.log("storage key.............", documentData.jsonStorageKey)
                toastNotify("success", "The Filled form has been updated successfully!");
            } else {
                toastNotify("success", "The Filled form has been successfully submitted as a required document!");
            }

        }
    }, [documentData]);
    console.log("contextSupportingDocscontextSupportingDocscontextSupportingDocscontextSupportingDocspppp", taskState.contextSupportingDocs, "docList", docList)

    return (
        <>
            <IframeModal open={showIframe} onClose={() => setShowIframe(false)} iframeUrl={iframeUrl} />
            <CustomReactMaterialTable
                staticLoad={true}
                staticData={contextSupportingDocs}
                columns={tableColumn}
                enablePagination={false}
                enableBottomToolbar={false}
                enableSorting={false}
                renderTopToolbarCustomActions={() => (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <Button variant="outlined" onClick={() => setModalOpen(true)}>Add</Button>
                    </Box>
                )}
                enableRowActions={true}
                renderRowActions={({ row }) => (
                    <Stack direction={'row'}>
                        {!row?.original['isFillableDocument'] === true && (
                            <IconButton disabled={row?.original['isFillableDocument'] == true} color="primary" onClick={() => previewDocument(row?.original)}>
                                <KSDocumentSearchIcon />
                            </IconButton>
                        )}
                        <ConfirmationModel
                            body={<IconButton onClick={() => setDeleteState(row.index)}><KSTrashIcon /></IconButton>}
                            message={`Are you sure you want to remove ${row?.original?.fileName}?`}
                            title={'Delete Document'}
                            handleSubmit={(event) => event.target.value === 'yes' && deleteSupportingDoc('id', row?.original?.id)}
                        />

                    </Stack>
                )}
            />
            <DocumentDialogCommonHooks
                show={modalOpen}
                handleClose={() => setModalOpen(false)}
                title={docEdit ? `Update Task Documents` : `Add Document`}
                body={<SupportingDocumentForm formId={formId} documentTypes={documentTypes} setLocalData={setLocalData} docList={docListLibrary} completedLoanDocs={completedLoanDocs} />}
            />
        </>
    );
}