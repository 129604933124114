import { Box, Button, Container, Grid, IconButton, List, ListItem, ListItemText, Stack, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { KSUploadIcon, KSUploadIconDoc } from "../../icons/custome-icon"
import { API } from "../../services/api-config";
import { toastNotify } from "../../helpers/alert-msg";
import { uploadFile, uploadFileWithToken } from "../../services/api-service";
import ThirdPartyUploadCard from "./third-party-upload-card";
import { KSCloudDownloadIcon, KSDocumentDownloadIcon, KSDocumentSearchIcon } from '../../icons/custome-icon'
import { docDownloadWithToken, docPreview, docPreviewResolve, docPreviewWithToken } from "../../helpers/file-upload-download";
import { previewLocalTaskDocument } from "../../helpers/preview-document";
import ThirdPartyChatDisplay from "./thirdparty-chat-display";
import { KSTooltip } from "../../helpers/ks-tooltip";
import { kstheme } from "../../theme/theme";
import IframeModal from '../third-party/iframeModal';
import { getCompleteDocumentUrl } from '../../helpers/common_fuctions/fill_online'


function ThirdPartyPreviewTask({
    formData, handleSend, handleCancelPreview,
    thirdPartyTaskDetails,
    accessCode,
    taskCode,
    setUploadFile,
    profileInfo
}) {
    const [documentData, setDocumentData] = useState({
        blanckDocId: '',
        filename: '',
        docId: '',
        storageKey: '',
        fillOnlineDocId: '',
        closeStatus: null,

        jsonStorageKey: ''
    });

    const [listDocumentTypes, setListDocumentTypes] = useState([])
    const [listSupportingDocs, setListSupportingDocs] = useState([])
    console.log("on the function", listDocumentTypes)
    console.log("on the function", listSupportingDocs)
    const isSmallScreen = useMediaQuery(kstheme.breakpoints.down("sm"));
    useEffect(() => {
        console.log(formData, 'formData');

        setListDocumentTypes(formData?.requiredDocuments);

        // Create a lookup dictionary for requiredDocuments based on document type
        const requiredDocumentsMap = {};
        formData?.requiredDocuments.forEach(doc => {
            requiredDocumentsMap[doc.documentType] = doc.isFillableDocument;
        });

        // Map over supportingSampleDocuments and update the isFillableDocument value
        const updatedSupportingDocs = formData?.supportingSampleDocuments.map(doc => {
            return {
                ...doc,
                isFillableDocument: requiredDocumentsMap[doc.documentName] ?? false // Default to false if not found
            };
        });
        console.log("formdata ::lkopo", updatedSupportingDocs)

        formData.supportingSampleDocuments = formData.supportingSampleDocuments.map(doc => {
            const matchingDoc = [doc].find(d => documentData['blankDocId'] == d.docId);
            console.log(documentData['blankDocId'], doc.docId, 'sokldo', matchingDoc, formData);
            return matchingDoc ? { ...doc, fillOnlineDocId: documentData.fillOnlineDocId } : doc;
        });

        setListSupportingDocs(updatedSupportingDocs);
    }, [formData, documentData]);


    console.log("supportingSampleDocumentssupportingSampleDocumentssupportingSampleDocuments", listDocumentTypes)

    function capitalize(name) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }






    const [localFileDetails, setLocalFileDetails] = useState(null);
    let [localData, setLocalData] = useState(null)




    const fileUpload = async (event, item) => {
        event.preventDefault(); // Prevents page reload


        let file = event.target.files[0];
        if (file) {
            const res = await uploadFileWithToken({
                formId: formData?.formId,
                // docId: taskFormData?.brokerDocId ? taskFormData?.brokerDocId : null,
                file: file,
                serviceType: "task",
                path: API.proxyDocumentThirdPartyOthersUpload,
                token: accessCode
            });

            if (res.status === 201) {
                toastNotify("success", res.data.message);
                setLocalFileDetails(res.data);

                // Retrieve form values after successful upload
                // if (documentType && documentName) {
                //     const formData = {
                //         documentType,
                //         documentName,
                //         ...res.data,
                //     };
                //     setLocalData(formData);
                //     //  handleClose();
                // } else {
                //     toastNotify("error", "Document type or name is missing.");
                // }
            } else {
                toastNotify("error", res.data.message);
            }
        } else {
            console.log("not found");
        }
    };




    const handleView = (row) => {


        if (row?.docType === 'LocalUpload') {


            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.proxyDocumentDownloadThirdParty
            const token = accessCode
            const docId = row?.docId

            const data = {

                storageKey: storageKey,
                // fileName: fileName,
                id: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {

                docPreviewWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            }

        } else {

            console.log(" Existing Preview")

            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.downloadDocResolve
            const token = accessCode
            //const docId = row?.docId

            const data = {

                storageKey: storageKey,
                fileName: fileName,
                // fileName: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {

                docPreviewWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            }
        }
    };


    const handleDownload = (row) => {


        if (row?.docType === 'LocalUpload') {


            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.proxyDocumentDownloadThirdParty
            const token = accessCode
            const docId = row?.docId

            const data = {

                storageKey: storageKey,
                // fileName: fileName,
                id: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {

                docDownloadWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            }

        } else {

            console.log(" Existing Preview")

            const storageKey = row?.storageKey;
            const fileName = row?.fileName;
            const apiUrl = API.downloadDocResolve
            const token = accessCode
            //const docId = row?.docId

            const data = {

                storageKey: storageKey,
                fileName: fileName,
                // fileName: docId,// in case local upload 
                thirdPartyTaskCode: taskCode
            }

            if (storageKey && fileName) {

                docDownloadWithToken(apiUrl, data, token)
                    .then((url) => {
                        console.log('Preview URL:', url);
                    })
                    .catch((error) => {
                        console.error('Error previewing the file:', error);
                    });
            }
        }
    };
    const [showIframe, setShowIframe] = useState(false); // State to control iframe rendering
    // coded by santha 

    // PS_23 Initialize state variables isFullscreen, iframeUrl, accessToken, documentName, and blankDocId.
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [iframeUrl, setIframeUrl] = useState(""); // Add this line to store the iframe URL
    const [accessToken, setAccessCode] = useState("");
    const [documentName, setDocumentName] = useState("");
    const [blankDocId, setBlankDocId] = useState(null);
    const [closeSate, setCloseState] = useState(null);


    // PS_24-25 Define handleFillFormClick function to handle the logic for filling out forms inside an iframe.
    const handleFillFormClick = (dataID, dataDoc) => {
        setCloseState(false)
        // PS_26 Check for the presence of blankDocId and documentName. If either is missing, log an error and exit the function.
        if (!dataID || !dataDoc) {
            console.error("Missing necessary data for iframe:", dataID, dataDoc);
            return;
        }
        // PS_27-28 Prepare iframeData with required properties such as formId, taskCode, blankDocId, documentName, taskType, and role.
        const iframeData = {
            formId: formData.formId,
            thirdPartyTaskCode: taskCode,
            blankdocId: dataID,
            documentType: dataDoc.documentName,
            taskType: formData.taskType.taskTypeName,
            thirdPartyCode: taskCode,
            role: profileInfo.toLowerCase(),
            taskCode: taskCode,
            docId: dataDoc.docId,
            BASE_URL: process.env.REACT_APP_BASE_URL
        };

        console.log("iframe data ::", iframeData)
        //PS_29 Update the state to show the iframe, set iframeData, and switch to fullscreen mode.
        //PS_30 Construct the iframeUrl with the query parameters from iframeData.
        //PS_31 Update the state variable iframeUrl with the constructed iframe URL.

        const queryParams = new URLSearchParams(iframeData).toString();
        const url = getCompleteDocumentUrl(dataDoc, queryParams);
        const iframeUrlWithToken = `${url}&access_token=${accessCode}`;
        setIframeUrl(iframeUrlWithToken);
        setShowIframe(true); // Show the modal
    };

    //  PS_38 Define handleDocumentClick function to process clicked document, update state with documentName and blankDocId, and call handleFillFormClick.
    const handleDocumentClick = (id) => {
        // setDocumentName(getSupportingDocumentById(docId));
        // setBlankDocId(getRequiredDocumentById(docId));
        console.log("docid", id)

        if (formData.taskStatus !== 'RESOLVED') {
            handleFillFormClick(getRequiredDocumentById(id), getSupportingDocumentById(id));// Call to fill form logic
        }
    };


    // PS_32 Define a useEffect hook to handle incoming messages from the iframe.
    useEffect(() => {
        // Function to handle messages received from the iframe
        const handleMessage = (event) => {
            console.log("Message received from iframe:", event);

            // Validate the origin - Replace 'http://localhost:3000' with your actual origin in production
            const allowedOrigin = 'https://fillonline.kramasoft.com';
            if (event.origin !== allowedOrigin) {
                console.warn("Message from unauthorized origin:", event.origin);
                return;
            }

            let data;
            // Check if event.data is a string or an object
            if (typeof event.data === 'string') {
                try {
                    data = JSON.parse(event.data);  // Attempt to parse JSON
                    console.log("eventdatastring", data);
                } catch (error) {
                    console.error("Failed to parse event data:", error);
                    return;  // Exit if parsing fails
                }
            } else if (typeof event.data === 'object') {
                data = event.data;  // Use directly if it's already an object
                console.log("eventdataobject", event.data);
            } else {
                console.warn("Unexpected message format:", event.data);
                return;
            }

            console.log("outdata", data)
            // Update state with the received data
            // setDocumentData(prevState => ({
            //     ...prevState, // Keep the previous state intact
            //     blankDocId: data.blankDocId || prevState.blankDocId,
            //     fillOnlineDocId: data?.docStorageId != null ? data.docStorageId : prevState.fillOnlineDocId,
            //     fileName: data?.fileName || (data.jsonStorageKey != null || data.jsonStorageKey != undefined ? "" : prevState.fileName), // Update fileName if jsonStorageKey is null
            //     docId: data?.docId || prevState.docId,
            //     storageKey: data?.storageKey || (data.jsonStorageKey != null || data.jsonStorageKey != undefined ? "" : prevState.storageKey), // Update storageKey if jsonStorageKey is null
            //     closeStatus: data?.closeStatus || prevState.closeStatus,
            //     jsonStorageKey: data.jsonStorageKey
            // }));

            const matchingDoc = listDocumentTypes.find(doc => doc.blankDocId == data.blankDocId);
            console.log(matchingDoc?.resolvedDocStorageKey, matchingDoc?.resolvedDocFileName, matchingDoc?.resolvedDocId, "ioooooooo", matchingDoc)
            // Check if resolvedDocStorageKey, resolvedDocFileName, and resolvedDocId have values
            if (matchingDoc?.resolvedDocStorageKey && matchingDoc?.resolvedDocFileName && matchingDoc?.resolvedDocId) {
                // Map the data to the state
                setDocumentData(prevState => ({
                    ...prevState, // Keep the previous state intact
                    blankDocId: data.blankDocId,
                    fillOnlineDocId: data?.docStorageId != null ? data.docStorageId : prevState.fillOnlineDocId,
                    fileName: data?.fileName || matchingDoc?.resolvedDocFileName,
                    docId: data?.docId || matchingDoc?.resolvedDocId,
                    storageKey: data?.storageKey || matchingDoc?.resolvedDocStorageKey,
                    closeStatus: data?.closeStatus || prevState.closeStatus,
                    jsonStorageKey: data.jsonStorageKey
                }));
                console.log("kokokokokokokokokokokll", documentData)// <--- HERE
            } else {
                setDocumentData(prevState => ({
                    ...prevState, // Keep the previous state intact
                    blankDocId: data.blankDocId,
                    fillOnlineDocId: data?.docStorageId != null ? data.docStorageId : prevState.fillOnlineDocId,
                    fileName: data?.fileName || (data.jsonStorageKey != null || data.jsonStorageKey != undefined ? "" : prevState.fileName), // Update fileName if jsonStorageKey is null
                    docId: data?.docId,
                    storageKey: data?.storageKey || (data.jsonStorageKey != null || data.jsonStorageKey != undefined ? "" : prevState.storageKey), // Update storageKey if jsonStorageKey is null
                    closeStatus: data?.closeStatus,
                    jsonStorageKey: data.jsonStorageKey
                }));


                console.log("kokokokokokokokokokokl", documentData)// <--- HERE
            }
        };

        // Attach event listener to window object
        window.addEventListener('message', handleMessage);
        console.log("handlemessage", handleMessage);
        console.log("document data ", documentData);

        // Cleanup function to remove event listener when component unmounts
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    });  // Empty dependency array to ensure it runs only once on mount


    // const getSupportingDocumentById = (docId) => {
    //     return formData.supportingSampleDocuments?.find(doc => doc.docId === docId)?.documentName;
    // };.
    const getSupportingDocumentById = (id) => {
        const doc = formData.supportingSampleDocuments?.find(doc => doc.docId === id);
        return doc ? { documentName: doc.documentName, docId: doc.fillOnlineDocId } : null;
    };

    // Function to fetch blankDocId from requiredDocuments based on document ID clicked
    const getRequiredDocumentById = (id) => {
        console.log("blankDocId", formData)
        console.log("docId", id)
        return formData.requiredDocuments?.find(doc => doc.blankDocId === id)?.blankDocId;
    };



    useEffect(() => {
        if (documentData.closeStatus) {
            console.log("hgiihihijhijijihdhkidhnfd");
            setShowIframe(false)
            console.log("storage key...............", documentData)
            if (documentData.jsonStorageKey != null) {
                console.log("storage key.............", typeof documentData.jsonStorageKey)
                toastNotify("success", "The Filled form has been updated successfully!");
            } else {
                toastNotify("success", "The Filled form has been successfully submitted as a required document!");
            }
        }
        console.log("789876567898765678");
    }, [documentData]);



    useEffect(() => {
        documentData.closeStatus = null
        console.log(documentData, "iframeUrliframeUrliframeUrl")
    }, [showIframe])





    return (<>
        <IframeModal open={showIframe} onClose={() => setShowIframe(false)} iframeUrl={iframeUrl} accessCode={accessCode} />
        <Container maxWidth={'lg'}>




            <Box >

                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="left">
                    <Box mr={2}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                <KSUploadIconDoc width={42} height={42} />
                            </Typography>

                        </Stack>

                    </Box>

                    <Box style={{ display: 'flex', flexDirection: 'column', padding: 0, }}>

                        <Box>

                            <Typography variant="h6"> </Typography>

                            <Grid item xs={12} md={6} mb={3}>
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    {/* <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                        {formData?.taskType?.taskTypeName}
                                    </Typography> */}
                                    <KSTooltip title={formData?.taskType?.taskTypeDescription} arrow>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            {formData?.taskType?.taskTypeName}
                                        </Typography>
                                    </KSTooltip>
                                    <Typography sx={{ fontSize: '14px', lineHeight: '18px', fontWeight: 400, color: '#2F80ED' }}>
                                        {formData?.taskPriority}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid container spacing={0} sx={{ flexGrow: 1, alignItems: "center" }}>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>

                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Borrower Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.borrowerFirstName && capitalize(formData.borrowerFirstName)}{' '}
                                            {formData?.borrowerLastName && capitalize(formData.borrowerLastName)}
                                        </Typography>

                                    </Stack>

                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            MLO Name:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.mloFirstName && capitalize(formData?.mloFirstName)}{' '}
                                            {formData?.mlolastName && capitalize(formData?.mlolastName)}

                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mt={2} mb={2}>

                                    <Stack
                                        // direction="row"
                                        // alignItems="flex-start"
                                        // justifyContent="space-between"
                                        // spacing={1}
                                        // sx={{ width: '80%' }}
                                        direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'flex-start'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}
                                    >
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Property:
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{ textAlign: 'right' }}
                                        >
                                            <Typography variant="body1" sx={{ textAlign: isSmallScreen ? 'left' : 'right' }}>
                                                {[
                                                    formData?.propertyAddress?.addressLineText,
                                                    formData?.propertyAddress?.addressAdditionalLineText,
                                                    formData?.propertyAddress?.cityName,
                                                    formData?.propertyAddress?.addressUnitIdentifier,
                                                    formData?.propertyAddress?.county,
                                                    formData?.propertyAddress?.postalCode,
                                                    formData?.propertyAddress?.stateCode,
                                                    formData?.propertyAddress?.countryCode,
                                                ]
                                                    .filter(Boolean)
                                                    .join(', ')
                                                }
                                            </Typography>


                                        </Typography>
                                    </Stack>


                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>

                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Loan Amount:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.loanAmount
                                                ? `$ ${new Intl.NumberFormat('en-US').format(formData?.loanAmount)}`
                                                : ''}
                                        </Typography>

                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>

                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Due Date:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.dueDate}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={6} mt={2} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Loan Type:
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.loanType}
                                        </Typography>
                                    </Stack>

                                </Grid>
                                <Grid item xs={12} md={6} mb={2}>
                                    <Stack direction={isSmallScreen ? 'column' : 'row'}
                                        alignItems={isSmallScreen ? 'flex-start' : 'center'}
                                        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
                                        spacing={1}
                                        sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Is lender Condition ?
                                        </Typography>
                                        <Typography variant="body1">
                                            {formData?.lenderCondition === true ? 'Yes' : 'No'}
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid sx={12} md={12} mt={2} mb={4}>
                                    <Typography>{formData?.taskDescription}</Typography>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} md={12} mt={4} mb={2}>
                                {(listSupportingDocs && listSupportingDocs.length > 0) ? (
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Supporting Documents
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>




                            <List>
                                <Grid container spacing={2}>
                                    {listSupportingDocs?.map((item, index) => (

                                        <Grid item xs={12} sm={6} key={index}>

                                            <Box
                                                sx={{
                                                    padding: '10px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '5px',
                                                    boxSizing: 'border-box',
                                                    width: '100%',
                                                }}
                                            >
                                                <ListItem
                                                    key={index}
                                                    secondaryAction={
                                                        <>
                                                            {item.isFillableDocument !== true && (
                                                                <>
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="view"
                                                                        color="primary"
                                                                        disabled={item.isFillableDocument == true}
                                                                        onClick={() => handleView(item)}
                                                                    >
                                                                        <KSDocumentSearchIcon />
                                                                    </IconButton>

                                                                    {/* IconButton for download */}
                                                                    <IconButton
                                                                        edge="end"
                                                                        aria-label="download"
                                                                        color="primary"
                                                                        disabled={item.isFillableDocument == true}
                                                                        onClick={() => handleDownload(item)}
                                                                    >
                                                                        <KSCloudDownloadIcon />
                                                                    </IconButton>
                                                                </>
                                                            )}
                                                        </>
                                                    }
                                                >
                                                    <ListItemText primary={`${index + 1}. ${item?.documentName}`}
                                                        secondary={item?.fileName} />
                                                </ListItem>
                                                {console.log("supportingDocsupportingDoc", listDocumentTypes)}
                                                {listDocumentTypes.map((reqitem) => {
                                                    { console.log("supportingDocsupportingDocmm", reqitem) }
                                                    // Find the supporting document where docId matches blankDocId
                                                    { console.log("suuuuuubbbb", formData.supportingSampleDocuments) }
                                                    const supportingDoc = formData.supportingSampleDocuments?.find(
                                                        (suppDoc) => suppDoc.docId == reqitem.blankDocId
                                                    );

                                                    if (supportingDoc) {
                                                        { console.log("supportingDocsupportingDoc", supportingDoc) }
                                                        // Case 1: Show "Fill Form" if isFillableDocument = true && blankDocument = false
                                                        if ((reqitem.blankDocId === item.docId) && (reqitem.isFillableDocument && !reqitem.blankDocument)) {
                                                            return (
                                                                <Box key={reqitem.blankDocId} >
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            color: formData.taskStatus === 'RESOLVED' ? '#B0B0B0' : '#3498db',
                                                                            cursor: formData.taskStatus === 'RESOLVED' ? 'not-allowed' : 'pointer',
                                                                            pointerEvents: formData.taskStatus === 'RESOLVED' ? 'none' : 'auto'
                                                                        }}
                                                                        onClick={() => handleDocumentClick(supportingDoc.docId)}
                                                                    >
                                                                        Fill Form
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        }

                                                        // Case 2: Show "Blank Document" if isFillableDocument = false && blankDocument = true
                                                        if ((reqitem.blankDocId === item.docId) && (!reqitem.isFillableDocument && reqitem.blankDocument)) {
                                                            return (
                                                                <Box key={reqitem.blankDocId}>
                                                                    <Typography variant="body2" sx={{ color: '#D94166' }}>
                                                                        Blank Document
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        }
                                                        if ((reqitem.blankDocId === item.docId) && (reqitem.isFillableDocument && reqitem.blankDocument)) {
                                                            return (
                                                                <Box key={reqitem.blankDocId}>
                                                                    <Typography variant="body2" sx={{ color: '#D94166' }}>
                                                                        Blank Document
                                                                    </Typography>
                                                                    <Typography
                                                                        variant="body2"
                                                                        sx={{
                                                                            color: formData.taskStatus === 'RESOLVED' ? '#B0B0B0' : '#3498db',
                                                                            cursor: formData.taskStatus === 'RESOLVED' ? 'not-allowed' : 'pointer',
                                                                            pointerEvents: formData.taskStatus === 'RESOLVED' ? 'none' : 'auto'
                                                                        }}
                                                                        onClick={() => handleDocumentClick(supportingDoc.docId)}
                                                                    >
                                                                        Fill Form
                                                                    </Typography>
                                                                </Box>
                                                            );
                                                        }

                                                        // Case 3: If isFillableDocument = false && blankDocument = false, show nothing
                                                        return null;
                                                    }

                                                    return null; // No matching supporting document, so nothing is rendered
                                                })

                                                }
                                            </Box>

                                        </Grid>
                                    ))}
                                </Grid>
                            </List>


                            <Grid item xs={12} md={12} mt={4} mb={2}>
                                {(listDocumentTypes && listDocumentTypes.length > 0) ? (
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} sx={{ width: '80%' }}>
                                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                                            Required Documents
                                        </Typography>
                                    </Stack>
                                ) : null}
                            </Grid>





                            <List>
                                {listDocumentTypes?.map((item, index) => (
                                    <ThirdPartyUploadCard
                                        key={item.id}
                                        item={item}
                                        accessCode={accessCode}
                                        fileUpload={fileUpload}
                                        formId={formData?.formId}
                                        setUploadFile={setUploadFile}
                                        taskCode={taskCode}
                                        taskStatus={formData.taskStatus}
                                        documentData={documentData}// Passing the data to BorrowerUploadCard
                                    />
                                ))}
                            </List>
                            <ThirdPartyChatDisplay taskCode={taskCode} secure={true} accessCode={accessCode} thirdPartyName={formData?.taskReceiverName} />

                            <Grid container spacing={2} mt={3} mb={3}>
                                <Grid item xs={12} md={6}>

                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" gap={2} justifyContent="flex-end">

                                        <Button variant="text" onClick={handleCancelPreview}>
                                            Cancel
                                        </Button>
                                        {/* <Button variant="contained" onClick={handleSend}>
                                            Submit
                                        </Button> */}
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if (formData.taskStatus === 'RESOLVED') {
                                                    handleCancelPreview();
                                                } else {
                                                    handleSend(documentData);
                                                }
                                            }}
                                        >
                                            {formData.taskStatus === 'RESOLVED' ? 'OK' : 'Submit'}
                                        </Button>





                                    </Stack>
                                </Grid>
                            </Grid>


                        </Box >
                    </Box>
                </Box>
            </Box>
        </Container >
    </>
    );
}

export default ThirdPartyPreviewTask;
