


import { AttachMoney, } from '@mui/icons-material'
import { Box, Button, FormHelperText, FormLabel, InputLabel, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    ACCOUNT_TYPE,
    ASSET_OWNED_BY,
    ASSET_OWNED_BY_WITHOUT_PARTNER,
    BLANK_DOCUMENT_STATUS,
    FILLABLE_DOCUMENT_STATUS,
    STATUS_OF_REALTOR,
    TASK_VENDOR
} from '../../../constants/constatnt-variable'
import CustomInput from '../../../helpers/custom-input'
import CustomInputSideCheckbox from '../../../helpers/custom-input-side-checkbox'
import CustomDropDown from '../../../helpers/dropdown/custom-dropdown'
import { Controller, useForm, useWatch } from 'react-hook-form'
import CustomRadioChip from '../../../helpers/radio-chip'
import RadioButtonGroupDoc from '../../../helpers/radio-button-group-doc'
import { TaskTypeValidation } from '../../../constants/validation-rules'
import { KSCloudUploadIcon } from '../../../icons/custome-icon'
import { getApiCallDynamic, uploadDocCustom } from '../../../services/api-service'
import { API } from '../../../services/api-config'
import { toastNotify } from '../../../helpers/alert-msg'


export default function DocumentLibraryForm({ handleClose, onSubmit, editItem, hasPartner, setUploadData, docList, refreshStatus, setRefreshStatus, fillOnlineList }) {

    console.log(fillOnlineList, 'fillOnlineList')

    console.log("docList : ", docList, editItem);


    const [fileNameToView, setFileNameToView] = useState(editItem?.fileName);

    const [fillOnlineDocumentList, setFillOnlineDocumentList] = useState(null)
    const [selectedKey, setselectedKey] = useState('')



    const { control, setValue, handleSubmit, watch, formState: { errors } } = useForm({
        defaultValues: {
            id: editItem?.id,
            documentNote: editItem?.documentNote,
            documentType: editItem?.documentType,
            taskType: editItem?.taskMaster?.id,
            blankDocument: editItem?.blankDocument === true ? 'Yes' : 'No',
            fillableDocument: editItem ? (editItem.isFillableDocument === true ? 'Yes' : 'No') : '',
            fillableDocumentdropdown: editItem?.storageKey || '',
            documentDescriptor: editItem?.documentDescriptor,
            fileName: editItem?.blankDocument === true ? editItem?.fileName : '',
            storageKey: editItem?.blankDocument === true ? editItem?.storageKey : null,
            fillableDocumentdropdowns: '',
            checkeditoradd: editItem.length == 0 ? 'add' : 'edit',
            storeKeyVal: editItem?.blankDocument === true ? editItem?.storageKey : null,
        }

    });

    // Watch for changes in fillableDocument selection
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'fillableDocumentDropDown' && value.fillableDocumentDropDown) {
                console.log('Selected fillableDocumentDropDown value:', value.fillableDocumentDropDown);

                if (Array.isArray(fillOnlineDocumentList)) {
                    const selectedDoc = fillOnlineDocumentList.find(
                        doc => doc.value === value.fillableDocumentDropDown
                    );
                    console.log(selectedDoc, 'selectedDocselectedDoc')
                    if (selectedDoc) {
                        setValue('fileName', selectedDoc.fileName || '');
                        setValue('storageKey', selectedDoc.value || '');
                        setValue('documentType', selectedDoc.label || '');
                    } else {
                        console.warn('No matching document found for:', value.fillableDocumentDropDown);
                    }
                } else {
                    console.error('fillOnlineDocumentList is not available or is invalid');
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, setValue, fillOnlineDocumentList]);


    const {
        blankDocument,
        brokerDocId,
        documentType,
        taskType,
        documentDescriptor,
        fillableDocument,
        fillableDocumentdropdown,
        fillableDocumentdropdowns,
        fileName,
        storageKey,
        storeKeyVal

    } = useWatch({ control });

    console.log(fileName, 'fileNamefileName')
    const [file, setFile] = useState(null);
    const [uploadResponse, setUploadResponse] = useState(null);





    const fillOnlineListsData = async () => {
        try {
            let param = { docType: "All" };
            const res = await getApiCallDynamic({ path: API.getFillOnlineDocument, param: param });
            console.log(res.data, 'res.data111')
            if (res?.status === 200) {
                if (Array.isArray(res.data)) {
                    const processedList = res.data.map((doc) => ({
                        id: doc.id,
                        label: doc.blankDocType,
                        value: doc.fileStorageKey,
                        fileName: doc.fileName,
                        jsonFileName: doc.jsonFileName,
                        jsonStorageKey: doc.jsonStorageKey,
                      documentType: doc.documentType,
                    }));
                    console.log(processedList, 'processedList')
                    setFillOnlineDocumentList(processedList);
                }
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    useEffect(() => {
        fillOnlineListsData()
    }, [])


    const handleFileChange = async (event) => {

        if (blankDocument === 'Yes') {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);


            const uploadPath = API.uploadDoc
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('taskTypeId', taskType);
            formData.append('documentType', documentType);
            formData.append('documentDescriptor', documentDescriptor);
            formData.append('serviceType', 'DocLib');


            try {

                const response = await uploadDocCustom({ formData, path: uploadPath });

                setUploadData(response.data)
                setFileNameToView(response.data.fileName)
                setUploadResponse(response.data);
                toastNotify("success", 'File uploaded successfully');
                setRefreshStatus(!refreshStatus)
                setValue
            } catch (error) {
                console.error('Error uploading file:', error);
                toastNotify("error", 'Error uploading file');

            }
        }
    };



    useEffect(() => {
        if (blankDocument === 'No') {
            setValue('fileName', null)
            setValue('storageKey', null)
            setValue('blankDocument', 'No')

        }
        else if (blankDocument === 'Yes') {

            setValue('fileName', editItem?.fileName)
            setValue('storageKey', editItem?.storageKey)
            setValue('blankDocument', 'Yes')

        }

    }, [blankDocument])


    useEffect(() => {
        console.log(fillOnlineDocumentList, fillOnlineList, 'fillOnlineListvalue')
        if (fillableDocument === 'No') {
            setValue('fileName', '')
            setValue('storageKey', '')
            setValue('isFillableDocument', 'No')

        }
        else if (fillableDocument === 'Yes') {
            console.log(editItem, 'editItem',taskType)
            setValue('id', editItem?.id)
            // const fileName = fillOnlineDocumentList.fileName == null
            //     ? (editItem?.id === fillOnlineList?.value ? fillOnlineList?.label : '')
            //     : fillOnlineDocumentList.fileName;
            // setValue('fillableDocumentdropdown', editItem?.storageKey)
            setValue('fileName', fillOnlineDocumentList?.fileName == null || editItem?.fileName)
            setValue('storageKey', fillOnlineDocumentList?.storageKey)
            setValue('isFillableDocument', 'Yes')
            console.log(fillOnlineDocumentList?.storageKey, "fillOnlineDocumentList.storageKey")



        }

    }, [fillableDocument])

    useEffect(() => {
        if (fillableDocument === 'Yes' && fillOnlineDocumentList?.length) {
            // Find the document that matches the editItem's storageKey
            const defaultOption = fillOnlineDocumentList.find(doc => doc.value === editItem?.storageKey);
            console.log(defaultOption, 'defaultOptiondefaultOption,', fillableDocumentdropdown)
            // Ensure the defaultOption is found and set the value correctly
            if (defaultOption) {
                setselectedKey(defaultOption.label)
                // setValue('fillableDocumentdropdown', defaultOption.value); // Set the dropdown value
                // setValue("fillableDocumentdropdowns", defaultOption.fileName);
                setValue('fileName', defaultOption.fileName); // Set the fileName
                setValue('storageKey', defaultOption.value); // Set the storageKey
            } else {
                // Handle case where no matching document is found (optional)
                console.warn('No matching document found for storageKey:', editItem?.storageKey);
            }
        } else if (fillableDocument === 'No') {
            // setValue('fillableDocumentdropdown', ''); // Reset the value if fillableDocument is 'No'
        }
    }, [fillableDocument, fillOnlineDocumentList, setValue, editItem, fillableDocumentdropdown]);


    useEffect(() => {
        console.log(fillableDocumentdropdown, 'fillableDocumentdropdownsfillableDocumentdropdowns', fillOnlineDocumentList);
        fillOnlineDocumentList?.map((val) => {
            if (val.value == fillableDocumentdropdown) {
                setValue('fileName', val.fileName);
                setValue('documentType', val.documentType);
                setValue('fillableDocumentdropdowns', val.fileName);
                setValue('storageKey', val.value);
                setValue('storeKeyVal', val.value);
                setValue('id', editItem?.id);
                setValue('id', editItem?.id);


            }
        })
    }, [fillableDocumentdropdown])
    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <Box width={'100%'} my={2} >
                <Box>

                    <Controller
                        name='taskType'
                        control={control}
                        rules={TaskTypeValidation.taskType}
                        render={({ field }) => (
                            <CustomDropDown
                                {...field}
                                field={field}
                                label={'Select the Task Type'}
                                required
                                //state={taskType}
                                option={docList}
                                error={errors?.taskType?.message}
                            />
                        )}
                    />

                    <Controller
                        name="documentType"
                        control={control}
                        rules={TaskTypeValidation.documentType}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter document type type'}
                                type={'text'} label={'Enter Document Type'} required error={errors?.documentType?.message} />
                        )}
                    />

                    <Controller
                        name="documentDescriptor"
                        control={control}
                        rules={TaskTypeValidation.documentDescriptor}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'None'}
                                type={'text'} label={'Enter Document Descriptor'} required error={errors?.documentDescriptor?.message} />
                        )}
                    />

                    <Controller
                        name="documentNote"
                        control={control}
                        rules={TaskTypeValidation.documentNote}
                        render={({ field }) => (
                            <CustomInput {...field}
                                placeholder={'Enter the instructions for filling the document'}
                                type={'text'} label={'Note for Filling the Document'} required error={errors?.documentNote?.message}
                                multiline
                                rows={5}
                            />
                        )}
                    />



                    <Controller
                        name="blankDocument"
                        control={control}
                        rules={TaskTypeValidation.isBlankDoc}
                        render={({ field }) => (
                            <RadioButtonGroupDoc
                                {...field}
                                field={field}
                                required
                                direction="row"
                                labelComponent={<FormLabel>{`Is Blank document available?`}</FormLabel>}
                                data={BLANK_DOCUMENT_STATUS}
                                error={errors?.isBlankDoc?.message}
                            />
                        )}
                    />

                    {
                        blankDocument === 'No' &&
                        <Controller
                            name="fillableDocument"
                            control={control}
                            rules={TaskTypeValidation.isFillableDoc}
                            render={({ field }) => (
                                <RadioButtonGroupDoc
                                    {...field}
                                    field={field}
                                    required
                                    direction="row"
                                    labelComponent={<FormLabel>{`Is fillable document available?`}</FormLabel>}
                                    data={FILLABLE_DOCUMENT_STATUS}
                                    error={errors?.isFillableDoc?.message}
                                />
                            )}
                        />
                    }
                    {
                        fillableDocument === "Yes" && (

                            // <Controller
                            //     name='fillableDocumentdropdown'
                            //     control={control}
                            //     // rules={TaskTypeValidation.isFillableDoc}
                            //     // defaultValue={editItem.fileName}
                            //     render={({ field }) => (
                            //         <CustomDropDown
                            //             {...field}
                            //             field={field}
                            //             label={'Select fillable document'}
                            //             required
                            //             //state={taskType}
                            //             onChange={(e) => {
                            //                 const selectedValue = e.target.value;
                            //                 const selectedDoc = fillOnlineDocumentList.find(doc => doc.value === selectedValue);

                            //                 if (selectedDoc) {
                            //                     setValue("fileName", selectedDoc.fileName || "");
                            //                     setValue("storageKey", selectedDoc.value || "");
                            //                     setValue("fillableDocumentdropdown", selectedValue);
                            //                 }
                            //             }}
                            //             option={fillOnlineDocumentList}
                            //             value={field.value || ""}
                            //             error={errors?.isFillableDoc?.message}
                            //         />
                            //     )}
                            // />

                            <Controller
                                name="fillableDocumentdropdown"
                                control={control}
                                render={({ field }) => (
                                    <CustomDropDown
                                        {...field}
                                        field={field}
                                        label="Select fillable document"
                                        required
                                        option={fillOnlineDocumentList}
                                        value={field.value || ""}
                                        onChange={(e) => {
                                            console.log(e, 'ooooooo')
                                            const selectedValue = e.target.value;
                                            const selectedDoc = fillOnlineDocumentList.find(doc => doc.value === selectedValue);
                                            console.log(selectedDoc, 'selectedDoc')
                                            if (selectedDoc) {
                                                setValue("fileName", selectedDoc.fileName || "");
                                                setValue("storageKey", selectedDoc.value || "");
                                                setValue("fillableDocumentdropdown", selectedDoc.value);
                                                setValue("fillableDocumentdropdowns", selectedDoc);
                                                setValue("documentType", selectedDoc.label);
                                            }
                                        }}
                                        error={errors?.fillableDocumentdropdown?.message}
                                    />
                                )}
                            />

                        )
                    }


                </Box>

                <Box marginY={4} textAlign={'right'}>

                    {
                        blankDocument === 'Yes' &&

                        <Stack
                            direction={"row"}
                            gap={1}
                            alignItems={"center"}
                            my={2}
                            justifyContent={"space-between"}
                        >
                            {/* <InputLabel>{`Document to Sign`}
                                                <Typography component={'span'} color={'error'}>{`*`}</Typography>
                                            </InputLabel> */}


                            <Button
                                component="label"
                                size="small"
                                // {...field}
                                // value={brokerFileID}
                                startIcon={<KSCloudUploadIcon color={"primary"} />}
                                sx={{
                                    mb: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    gap: 1
                                }}
                            >
                                <input
                                    onChange={handleFileChange}
                                    hidden
                                    accept="image/*"
                                    type="file"
                                />
                                <Typography paragraph>
                                    {fileNameToView ? `Reupload` : `Upload`}
                                </Typography>

                                <Typography paragraph> {fileNameToView}</Typography>
                            </Button>

                        </Stack>



                    }



                    {/* <Button type='submit' variant='contained' >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button> */}

                    <Button
                        type="button"
                        onClick={handleClose}

                        sx={{

                            width: '50%',
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="submit"
                        variant="contained"
                        disabled={blankDocument === 'Yes' && !fileNameToView}
                        sx={{

                            width: '50%',
                        }}
                    >
                        {editItem.length !== 0 ? 'Update' : 'Add'}
                    </Button>
                </Box>
            </Box>
        </form>

    )
}